import { Controller } from "@hotwired/stimulus";
import { createPopup } from "@picmo/popup-picker";

/**
 * Picker for selecting an emoji.
 */
export default class extends Controller {
    static targets = ["input"];

    connect() {
        this.inputTarget.setAttribute('readonly', true);

        const picker = createPopup(
            {},
            {
                referenceElement: this.inputTarget,
                triggerElement: this.inputTarget,
                position: "bottom-start",
                showCloseButton: false
            }
        );

        this.inputTarget.addEventListener("click", () => {
            picker.toggle();
        });

        picker.addEventListener("emoji:select", (selection) => {
            this.inputTarget.value = selection.emoji;
            document.dispatchEvent(new CustomEvent('updateGalleryPreview'));
        });
    }
}
