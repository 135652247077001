import { Controller } from '@hotwired/stimulus';

export default class IframeScrollController extends Controller {
    static values = { sectionType: String }
    iframe = null;
    isFirstLoad = true;

    connect() {
        this.setIframeLoadListener();
        this.scrollToSection();
        this.isFirstLoad = false; 
    }

    setIframeLoadListener() {
        this.iframe = document.getElementById('galleryPreviewIframe');
        if (this.iframe) {
            this.iframeLoadListener = () => this.scrollToSection();
            this.iframe.addEventListener('load', this.iframeLoadListener);
        }
    }

    scrollToSection() {
        if (this.iframe) {
            if (this.isFirstLoad) {
              this.iframe.contentWindow.postMessage({ scrollTo: this.sectionTypeValue + '-section', scrollType: 'smooth' }, '*');
            } else {
              this.iframe.contentWindow.postMessage({ scrollTo: this.sectionTypeValue + '-section', scrollType: 'instant' }, '*');
            }
        }
    }

    disconnect() {
        if (this.iframe && this.iframeLoadListener) {
            this.iframe.contentWindow.scrollTo(0, 0);
            this.iframe.removeEventListener('load', this.iframeLoadListener);
        }
    }
}