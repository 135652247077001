import { Controller } from "@hotwired/stimulus";

/**
 * Toggles the collapsed state of the main menu.
 * Titles are added to collapsed menu items to make them visible on hover and accessible to screen readers.
 */
export default class extends Controller {

  static targets = ["expand", "collapse"]

    initialize() {
        super.initialize();

        this.expander = this.expandTarget;
        this.collapser = this.collapseTarget;
        this.expander.addEventListener("click", this.toggle.bind(this));
        this.collapser.addEventListener("click", this.toggle.bind(this));
        this.controlledTarget = document.getElementById(this.element.getAttribute("aria-controls"));
        this.mobileAnimation = document.querySelector(".chili");

        if(!this.isExpanded()) {
            this.addTitleAttributesToMenuItems();
        }
    }

    toggle(event) {
        event.preventDefault();
        event.stopPropagation();
        
        let wasExpanded = this.isExpanded();
        this.element.setAttribute("aria-expanded", !wasExpanded);
        localStorage.setItem('menuSidebarExpanded', !wasExpanded);

        if(wasExpanded) {
            this.addTitleAttributesToMenuItems();
            this.controlledTarget.classList.add("collapsed");
            this.mobileAnimation.classList.remove("expanded");
        }
        else {
            this.controlledTarget.classList.remove("collapsed");
            this.mobileAnimation.classList.add("expanded");
            this.removeTitleAttributesFromMenuItems();
        }
    }

    addTitleAttributesToMenuItems() {
        let menuItems = this.controlledTarget.querySelectorAll("a:not(.user-item)");
        menuItems.forEach((menuItem) => {
            menuItem.setAttribute("title", menuItem.textContent.trim());
        });

        let userItem = this.controlledTarget.querySelector("a.user-item");
        userItem.setAttribute("title", userItem.querySelector(".name").textContent.trim());
    }

    isExpanded() {
        return this.element.getAttribute("aria-expanded") == "true";
    }

    removeTitleAttributesFromMenuItems() {
        let menuItems = this.controlledTarget.querySelectorAll("a");
        menuItems.forEach((menuItem) => {
            menuItem.removeAttribute("title");
        });
    }
}
