import { Application } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import "@hotwired/turbo";
import * as ActiveStorage from "@rails/activestorage"
import '@selectize/selectize';

require("trix")
require("@rails/actiontext")
require("../packs/direct_uploads")

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

Rails.start()
ActiveStorage.start()
Turbo.setFormMode('off');
