import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle"];

  toggle() {
    const isChecked = this.toggleTarget.checked;
    const container = this.toggleTarget.closest(".section-container");

    if (container) {
      const iconPaths = container.querySelectorAll(".section-icon .icon path");
      const title = container.querySelector(".section-info .title");
      const description = container.querySelector(".section-info .description");
      const linkTarget = container.querySelector("a");

      iconPaths.forEach(path => {
        path.style.fill = isChecked ? "" : "lightgrey";
      });

      if (title) {
        title.style.color = isChecked ? "" : "lightgrey";
      }
      if (description) {
        description.style.color = isChecked ? "" : "lightgrey";
      }

      if (linkTarget) {
        if (isChecked) {
          linkTarget.classList.remove("disabled");
        } else {
          linkTarget.classList.add("disabled");
        }

        linkTarget.querySelector("i").style.color = isChecked ? "" : "lightgrey";
      }
    }
  }
}
