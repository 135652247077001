import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        link: String
    }

    copy() {
        if (!navigator.clipboard) {
            console.warn("Cannot use clipboard API")
            return
        }

        navigator.clipboard.writeText(this.linkValue).then(() => {
            alert('Copied to clipboard')
        })
    }
}