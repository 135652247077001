import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import Rails from "@rails/ujs"; // Make sure to import axios or use any other library to make HTTP requests

export default class extends Controller {
  static values = {
    endpoint: { type: String },
    valueField: { type: String },
    textField: { type: String },
    queryParam: { type: String, default: "q" },
    sortField: { type: String, default: "text" },
    allowCreate: { type: Boolean, default: false },
  };

  initialize() {
    const elementId = this.element.getAttribute("id");
    const valueField = this.valueFieldValue;
    const textField = this.textFieldValue;
    const buildApiUrl = this.buildApiUrl.bind(this);

    if (!elementId) {
      console.log("No ID found for selectize element", this.element);
    }

    let currentQuery = "";
    $("#" + elementId).selectize({
      plugins: ["restore_on_backspace", "clear_button", "remove_button"],
      create: this.allowCreateValue,
      persist: false,
      maxItems: null,
      score: function (search) {
        // override standard client-side filtering of results, which were only matching when items started with our query.
        // see: https://stackoverflow.com/a/35920145
        return function (item) {
          // case-insensitive match against our current query to show correct partial matches.
          return new RegExp(currentQuery, "i").test(item.text) ? 1 : 0;
        };
      },
      sortField: this.sortFieldValue,
      load: function (query, callback) {
        currentQuery = query;
        Rails.ajax({
          type: "GET",
          url: buildApiUrl(query),
          dataType: "json",
          success: (data) => {
            callback(
              data.map((item) => ({
                value: item[valueField] ? item[valueField] : item,
                text: item[textField] ? item[textField] : item,
              })),
            );
          },
        });
      },
      onChange: function (value) {
        window.dispatchEvent(new CustomEvent("selectize:onchange"));
      },
    });
  }

  buildApiUrl(query) {
    const url = new URL(this.endpointValue);
    url.searchParams.append(this.queryParamValue, query);
    return url.href;
  }
}
