import { Controller } from '@hotwired/stimulus';
import Masonry from "masonry-layout";
import 'jquery'

export default class extends Controller {

  connect() {
    new Masonry('.gallery-grid', {
      itemSelector: '.gallery-item',
      columnWidth: '.grid-sizer'
    })
  }
}
