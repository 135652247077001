import { Controller } from '@hotwired/stimulus';

export default class PriceController extends Controller {
  static targets = ["slider", "output", "standard", "extended", "premium"];

  connect() {
    this.ensureOneChecked();
    this.updateOutput();
  }

  updateOutput() {
    let priceText = '';
    if (this.standardTarget.checked) {
      priceText = ' (Standard Licensing)';
    } else if (this.extendedTarget.checked) {
      priceText = ' (Extended Licensing)';
    } else if (this.premiumTarget.checked) {
      priceText = ' (Premium Licensing)';
    }
    this.outputTarget.textContent = '$' + this.numberWithCommas(this.sliderTarget.value) + ' or less' + priceText;
  }

  numberWithCommas(x) {
    return parseFloat(x).toLocaleString();
  }

  toggleCheckbox(event) {
    if (event.target === this.standardTarget && this.standardTarget.checked) {
      this.extendedTarget.checked = false;
      this.premiumTarget.checked = false;
    } else if (event.target === this.extendedTarget && this.extendedTarget.checked) {
      this.standardTarget.checked = false;
      this.premiumTarget.checked = false;
    } else if (event.target === this.premiumTarget && this.premiumTarget.checked) {
      this.standardTarget.checked = false;
      this.extendedTarget.checked = false;
    } else {
      event.target.checked = true; // Re-check if trying to uncheck the only checked box
    }
    this.updateOutput();
  }

  ensureOneChecked() {
    if (!this.standardTarget.checked && !this.extendedTarget.checked && !this.premiumTarget.checked) {
      this.standardTarget.checked = true; // Default to 'Standard Licensing' if none is checked
    }
  }
}
