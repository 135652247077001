import { Controller } from '@hotwired/stimulus'

/*
 * Removes the <code>hidden</code> class to the selector-targeted element.
 */
export default class extends Controller {
    static values = {
        selector: String
    };

    unhide(event) {
        event.preventDefault();
        event.stopPropagation();

        let target = document.querySelector(this.selectorValue);
        target.classList.remove('!hidden');

        return false;
    }
}
