import { Controller } from '@hotwired/stimulus'
import $ from 'jquery';

export default class extends Controller {
  static targets = ["input"]
  static values = {
    id: String
  }

  initialize() {
    const elementId = this.inputTarget.getAttribute('id');
    if (!elementId) {
      console.log("No ID found for selectize element", this.element);
    }

    const selectElement = $(`#${elementId}`)

    selectElement.selectize({
      persist: false,
      maxItems: 1,
      sortField: 'text',
      labelField: 'name',
      searchField: 'name',
      onChange: () => {
        document.dispatchEvent(new CustomEvent('updateGalleryPreview'));
      }
    })

    selectElement.data('selectize').setValue(this.idValue);
  }
}