import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["fact"];

  connect() {
    this.hideExtraFacts();
  }

  hideExtraFacts() {
    this.factTargets.forEach((fact, index) => {
      if (index > 2) fact.style.display = 'none';
      if (index === 2) fact.style.borderBottom = 'none';
    });
  }

  toggleFacts() {
    const button = this.element.querySelector('#showMoreButton');

    if (button.innerText === "SHOW MORE") {
      this.factTargets.forEach((fact, index) => {
        if (index > 2) fact.style.display = 'flex';
      });
      if (this.factTargets[2]) {
        this.factTargets[2].style.borderBottom = '1px solid #ccc';  // Restore border of the 3rd fact
      }
      button.innerText = "SHOW LESS";
    } else {
      this.factTargets.forEach((fact, index) => {
        if (index > 2) fact.style.display = 'none';
        if (index === 2) fact.style.borderBottom = 'none';  // Hide border of the 3rd fact
      });
      button.innerText = "SHOW MORE";
    }
  }
}

