import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ['left', 'right', 'carousel']

  initialize() {
    this.index = 0
    this.leftTarget.disabled = true
    this.leftTarget.classList.add('opacity-0') //can't add classList=('hidden') to the element or it messes with functionality
  }

  next() {
    if (this.index < 2) {
      this.index++
    }

    this.updateButtons()
  }

  previous() {
    if (this.index > 0) {
      this.index--
    }

    this.updateButtons()
  }


  updateButtons() {

    if (this.index == 0) {
      this.leftTarget.disabled = true
      this.leftTarget.classList.add('opacity-0')
      this.rightTarget.disabled = false
      this.rightTarget.classList.remove('opacity-0')
    } else if (this.index == 2) {
      this.rightTarget.disabled = true
      this.rightTarget.classList.add('opacity-0')
      this.leftTarget.disabled = false
      this.leftTarget.classList.remove('opacity-0')
    } else {
      this.rightTarget.disabled = false
      this.rightTarget.classList.remove('opacity-0')
      this.leftTarget.disabled = false
      this.leftTarget.classList.remove('opacity-0')
    }
  }
}

