const icons = [
  { name: 'fab fa-instagram' },
  { name: 'fab fa-youtube' },
  { name: 'fab fa-facebook-f' },
  { name: 'fab fa-tiktok' },
  { name: 'fab fa-twitter' },
  { name: 'fas fa-envelope' },
  { name: 'fas fa-phone' },
  { name: 'fas fa-map-marker' },
  { name: 'fas fa-user' },
  { name: 'fas fa-cog' },
  { name: 'fas fa-star' },
  { name: 'fas fa-heart' },
  { name: 'fas fa-shopping-cart' },
  { name: 'fas fa-camera' },
  { name: 'fas fa-music' },
  { name: 'fas fa-film' },
  { name: 'fas fa-book' },
  { name: 'fas fa-gamepad' },
  { name: 'fas fa-microphone' },
  { name: 'fas fa-pencil-alt' },
  { name: 'fas fa-address-book' },
  { name: 'fas fa-address-card' },
  { name: 'fas fa-adjust' },
  { name: 'fas fa-align-center' },
  { name: 'fas fa-align-justify' },
  { name: 'fas fa-align-left' },
  { name: 'fas fa-align-right' },
  { name: 'fas fa-allergies' },
  { name: 'fas fa-ambulance' },
  { name: 'fas fa-american-sign-language-interpreting' },
  { name: 'fas fa-anchor' },
  { name: 'fas fa-angle-double-down' },
  { name: 'fas fa-angle-double-left' },
  { name: 'fas fa-angle-double-right' },
  { name: 'fas fa-angle-double-up' },
  { name: 'fas fa-angle-down' },
  { name: 'fas fa-angle-left' },
  { name: 'fas fa-angle-right' },
  { name: 'fas fa-angle-up' },
  { name: 'fas fa-angry' },
  { name: 'fas fa-ankh' },
  { name: 'fas fa-apple-alt' },
  { name: 'fas fa-archive' },
  { name: 'fas fa-archway' },
  { name: 'fas fa-arrow-alt-circle-down' },
  { name: 'fas fa-arrow-alt-circle-left' },
  { name: 'fas fa-arrow-alt-circle-right' },
  { name: 'fas fa-arrow-alt-circle-up' },
  { name: 'fas fa-arrow-circle-down' },
  { name: 'fas fa-arrow-circle-left' },
  { name: 'fas fa-arrow-circle-right' },
  { name: 'fas fa-arrow-circle-up' },
  { name: 'fas fa-arrow-down' },
  { name: 'fas fa-arrow-left' },
  { name: 'fas fa-arrow-right' },
  { name: 'fas fa-arrow-up' },
  { name: 'fas fa-arrows-alt' },
  { name: 'fas fa-arrows-alt-h' },
  { name: 'fas fa-arrows-alt-v' },
  { name: 'fas fa-assistive-listening-systems' },
  { name: 'fas fa-asterisk' },
  { name: 'fas fa-at' },
  { name: 'fas fa-atlas' },
  { name: 'fas fa-atom' },
  { name: 'fas fa-audio-description' },
  { name: 'fas fa-award' },
  { name: 'fas fa-baby' },
  { name: 'fas fa-baby-carriage' },
  { name: 'fas fa-backspace' },
  { name: 'fas fa-backward' },
  { name: 'fas fa-bacon' },
  { name: 'fas fa-balance-scale' },
  { name: 'fas fa-ban' },
  { name: 'fas fa-band-aid' },
  { name: 'fas fa-barcode' },
  { name: 'fas fa-bars' },
  { name: 'fas fa-baseball-ball' },
  { name: 'fas fa-basketball-ball' },
  { name: 'fas fa-bath' },
  { name: 'fas fa-battery-empty' },
  { name: 'fas fa-battery-full' },
  { name: 'fas fa-battery-half' },
  { name: 'fas fa-battery-quarter' },
  { name: 'fas fa-battery-three-quarters' },
  { name: 'fas fa-bed' },
  { name: 'fas fa-beer' },
  { name: 'fas fa-bell' },
  { name: 'fas fa-bell-slash' },
  { name: 'fas fa-bezier-curve' },
  { name: 'fas fa-bible' },
  { name: 'fas fa-bicycle' },
  { name: 'fas fa-binoculars' },
  { name: 'fas fa-birthday-cake' },
  { name: 'fas fa-blender' },
  { name: 'fas fa-blind' },
  { name: 'fas fa-blog' },
  { name: 'fas fa-bold' },
  { name: 'fas fa-bolt' },
  { name: 'fas fa-bomb' },
  { name: 'fas fa-bone' },
  { name: 'fas fa-bong' },
  { name: 'fas fa-book-dead' },
  { name: 'fas fa-book-open' },
  { name: 'fas fa-book-reader' },
  { name: 'fas fa-bookmark' },
  { name: 'fas fa-bowling-ball' },
  { name: 'fas fa-box' },
  { name: 'fas fa-box-open' },
  { name: 'fas fa-boxes' },
  { name: 'fas fa-braille' },
  { name: 'fas fa-brain' },
  { name: 'fas fa-bread-slice' },
  { name: 'fas fa-briefcase' },
  { name: 'fas fa-briefcase-medical' },
  { name: 'fas fa-broadcast-tower' },
  { name: 'fas fa-broom' },
  { name: 'fas fa-brush' },
  { name: 'fas fa-bug' },
  { name: 'fas fa-building' },
  { name: 'fas fa-bullhorn' },
  { name: 'fas fa-bullseye' },
  { name: 'fas fa-burn' },
  { name: 'fas fa-bus' },
  { name: 'fas fa-bus-alt' },
  { name: 'fas fa-business-time' },
  { name: 'fas fa-calculator' },
  { name: 'fas fa-calendar' },
  { name: 'fas fa-calendar-alt' },
  { name: 'fas fa-calendar-check' },
  { name: 'fas fa-calendar-day' },
  { name: 'fas fa-calendar-minus' },
  { name: 'fas fa-calendar-plus' },
  { name: 'fas fa-calendar-times' },
  { name: 'fas fa-calendar-week' },
  { name: 'fas fa-camera-retro' },
  { name: 'fas fa-campground' },
  { name: 'fas fa-candy-cane' },
  { name: 'fas fa-cannabis' },
  { name: 'fas fa-capsules' },
  { name: 'fas fa-car' },
  { name: 'fas fa-car-alt' },
  { name: 'fas fa-car-battery' },
  { name: 'fas fa-car-crash' },
  { name: 'fas fa-car-side' },
  { name: 'fas fa-caravan' },
  { name: 'fas fa-caret-down' },
  { name: 'fas fa-caret-left' },
  { name: 'fas fa-caret-right' },
  { name: 'fas fa-caret-square-down' },
  { name: 'fas fa-caret-square-left' },
  { name: 'fas fa-caret-square-right' },
  { name: 'fas fa-caret-square-up' },
  { name: 'fas fa-caret-up' },
  { name: 'fas fa-carrot' },
  { name: 'fas fa-cart-arrow-down' },
  { name: 'fas fa-cart-plus' },
  { name: 'fas fa-cash-register' },
  { name: 'fas fa-cat' },
  { name: 'fas fa-certificate' },
  { name: 'fas fa-chair' },
  { name: 'fas fa-chalkboard' },
  { name: 'fas fa-chalkboard-teacher' },
  { name: 'fas fa-charging-station' },
  { name: 'fas fa-chart-area' },
  { name: 'fas fa-chart-bar' },
  { name: 'fas fa-chart-line' },
  { name: 'fas fa-chart-pie' },
  { name: 'fas fa-check' },
  { name: 'fas fa-check-circle' },
  { name: 'fas fa-check-double' },
  { name: 'fas fa-check-square' },
  { name: 'fas fa-cheese' },
  { name: 'fas fa-chess' },
  { name: 'fas fa-chess-bishop' },
  { name: 'fas fa-chess-board' },
  { name: 'fas fa-chess-king' },
  { name: 'fas fa-chess-knight' },
  { name: 'fas fa-chess-pawn' },
  { name: 'fas fa-chess-queen' },
  { name: 'fas fa-chess-rook' },
  { name: 'fas fa-chevron-circle-down' },
  { name: 'fas fa-chevron-circle-left' },
  { name: 'fas fa-chevron-circle-right' },
  { name: 'fas fa-chevron-circle-up' },
  { name: 'fas fa-chevron-down' },
  { name: 'fas fa-chevron-left' },
  { name: 'fas fa-chevron-right' },
  { name: 'fas fa-chevron-up' },
  { name: 'fas fa-child' },
  { name: 'fas fa-church' },
  { name: 'fas fa-circle' },
  { name: 'fas fa-circle-notch' },
  { name: 'fas fa-city' },
  { name: 'fas fa-clinic-medical' },
  { name: 'fas fa-clipboard' },
  { name: 'fas fa-clipboard-check' },
  { name: 'fas fa-clipboard-list' },
  { name: 'fas fa-clock' },
  { name: 'fas fa-clone' },
  { name: 'fas fa-closed-captioning' },
  { name: 'fas fa-cloud' },
  { name: 'fas fa-cloud-download-alt' },
  { name: 'fas fa-cloud-meatball' },
  { name: 'fas fa-cloud-moon' },
  { name: 'fas fa-cloud-moon-rain' },
  { name: 'fas fa-cloud-rain' },
  { name: 'fas fa-cloud-showers-heavy' },
  { name: 'fas fa-cloud-sun' },
  { name: 'fas fa-cloud-sun-rain' },
  { name: 'fas fa-cloud-upload-alt' },
  { name: 'fas fa-cocktail' },
  { name: 'fas fa-code' },
  { name: 'fas fa-code-branch' },
  { name: 'fas fa-coffee' },
  { name: 'fas fa-cog' },
  { name: 'fas fa-cogs' },
  { name: 'fas fa-coins' },
  { name: 'fas fa-columns' },
  { name: 'fas fa-comment' },
  { name: 'fas fa-comment-alt' },
  { name: 'fas fa-comment-dots' },
  { name: 'fas fa-comment-medical' },
  { name: 'fas fa-comment-slash' },
  { name: 'fas fa-comments' },
  { name: 'fas fa-compact-disc' },
  { name: 'fas fa-compass' },
  { name: 'fas fa-compress' },
  { name: 'fas fa-compress-arrows-alt' },
  { name: 'fas fa-concierge-bell' },
  { name: 'fas fa-cookie' },
  { name: 'fas fa-cookie-bite' },
  { name: 'fas fa-copy' },
  { name: 'fas fa-copyright' },
  { name: 'fas fa-couch' },
  { name: 'fas fa-credit-card' },
  { name: 'fas fa-crop' },
  { name: 'fas fa-crop-alt' },
  { name: 'fas fa-cross' },
  { name: 'fas fa-crosshairs' },
  { name: 'fas fa-crow' },
  { name: 'fas fa-crown' },
  { name: 'fas fa-crutch' },
  { name: 'fas fa-cube' },
  { name: 'fas fa-cubes' },
  { name: 'fas fa-cut' },
  { name: 'fas fa-database' },
  { name: 'fas fa-deaf' },
  { name: 'fas fa-democrat' },
  { name: 'fas fa-desktop' },
  { name: 'fas fa-dharmachakra' },
  { name: 'fas fa-diagnoses' },
  { name: 'fas fa-dice' },
  { name: 'fas fa-dice-d20' },
  { name: 'fas fa-dice-d6' },
  { name: 'fas fa-dice-five' },
  { name: 'fas fa-dice-four' },
  { name: 'fas fa-dice-one' },
  { name: 'fas fa-dice-six' },
  { name: 'fas fa-dice-three' },
  { name: 'fas fa-dice-two' },
  { name: 'fas fa-digital-tachograph' },
  { name: 'fas fa-directions' },
  { name: 'fas fa-divide' },
  { name: 'fas fa-dizzy' },
  { name: 'fas fa-dna' },
  { name: 'fas fa-dog' },
  { name: 'fas fa-dollar-sign' },
  { name: 'fas fa-dolly' },
  { name: 'fas fa-dolly-flatbed' },
  { name: 'fas fa-donate' },
  { name: 'fas fa-door-closed' },
  { name: 'fas fa-door-open' },
  { name: 'fas fa-dot-circle' },
  { name: 'fas fa-dove' },
  { name: 'fas fa-download' },
  { name: 'fas fa-drafting-compass' },
  { name: 'fas fa-dragon' },
  { name: 'fas fa-draw-polygon' },
  { name: 'fas fa-drum' },
  { name: 'fas fa-drum-steelpan' },
  { name: 'fas fa-drumstick-bite' },
  { name: 'fas fa-dumbbell' },
  { name: 'fas fa-dumpster' },
  { name: 'fas fa-dumpster-fire' },
  { name: 'fas fa-dungeon' },
  { name: 'fas fa-edit' },
  { name: 'fas fa-egg' },
  { name: 'fas fa-eject' },
  { name: 'fas fa-ellipsis-h' },
  { name: 'fas fa-ellipsis-v' },
  { name: 'fas fa-envelope-open' },
  { name: 'fas fa-envelope-open-text' },
  { name: 'fas fa-envelope-square' },
  { name: 'fas fa-equals' },
  { name: 'fas fa-eraser' },
  { name: 'fas fa-ethernet' },
  { name: 'fas fa-euro-sign' },
  { name: 'fas fa-exchange-alt' },
  { name: 'fas fa-exclamation' },
  { name: 'fas fa-exclamation-circle' },
  { name: 'fas fa-exclamation-triangle' },
  { name: 'fas fa-expand' },
  { name: 'fas fa-expand-arrows-alt' },
  { name: 'fas fa-external-link-alt' },
  { name: 'fas fa-external-link-square-alt' },
  { name: 'fas fa-eye' },
  { name: 'fas fa-eye-dropper' },
  { name: 'fas fa-eye-slash' },
  { name: 'fas fa-fan' },
  { name: 'fas fa-fast-backward' },
  { name: 'fas fa-fast-forward' },
  { name: 'fas fa-fax' },
  { name: 'fas fa-feather' },
  { name: 'fas fa-feather-alt' },
  { name: 'fas fa-female' },
  { name: 'fas fa-fighter-jet' },
  { name: 'fas fa-file' },
  { name: 'fas fa-file-alt' },
  { name: 'fas fa-file-archive' },
  { name: 'fas fa-file-audio' },
  { name: 'fas fa-file-code' },
  { name: 'fas fa-file-contract' },
  { name: 'fas fa-file-csv' },
  { name: 'fas fa-file-download' },
  { name: 'fas fa-file-excel' },
  { name: 'fas fa-file-export' },
  { name: 'fas fa-file-image' },
  { name: 'fas fa-file-import' },
  { name: 'fas fa-file-invoice' },
  { name: 'fas fa-file-invoice-dollar' },
  { name: 'fas fa-file-medical' },
  { name: 'fas fa-file-medical-alt' },
  { name: 'fas fa-file-pdf' },
  { name: 'fas fa-file-powerpoint' },
  { name: 'fas fa-file-prescription' },
  { name: 'fas fa-file-signature' },
  { name: 'fas fa-file-upload' },
  { name: 'fas fa-file-video' },
  { name: 'fas fa-file-word' },
  { name: 'fas fa-fill' },
  { name: 'fas fa-fill-drip' },
  { name: 'fas fa-film' },
  { name: 'fas fa-filter' },
  { name: 'fas fa-fingerprint' },
  { name: 'fas fa-fire' },
  { name: 'fas fa-fire-alt' },
  { name: 'fas fa-fire-extinguisher' },
  { name: 'fas fa-first-aid' },
  { name: 'fas fa-fish' },
  { name: 'fas fa-fist-raised' },
  { name: 'fas fa-flag' },
  { name: 'fas fa-flag-checkered' },
  { name: 'fas fa-flag-usa' },
  { name: 'fas fa-flask' },
  { name: 'fas fa-folder' },
  { name: 'fas fa-folder-open' },
  { name: 'fas fa-font' },
  { name: 'fas fa-football-ball' },
  { name: 'fas fa-forward' },
  { name: 'fas fa-frog' },
  { name: 'fas fa-frown' },
  { name: 'fas fa-frown-open' },
  { name: 'fas fa-funnel-dollar' },
  { name: 'fas fa-futbol' },
  { name: 'fas fa-gamepad' },
  { name: 'fas fa-gas-pump' },
  { name: 'fas fa-gavel' },
  { name: 'fas fa-gem' },
  { name: 'fas fa-genderless' },
  { name: 'fas fa-ghost' },
  { name: 'fas fa-gift' },
  { name: 'fas fa-gifts' },
  { name: 'fas fa-glass-cheers' },
  { name: 'fas fa-glass-martini' },
  { name: 'fas fa-glass-martini-alt' },
  { name: 'fas fa-glass-whiskey' },
  { name: 'fas fa-glasses' },
  { name: 'fas fa-globe' },
  { name: 'fas fa-globe-africa' },
  { name: 'fas fa-globe-americas' },
  { name: 'fas fa-globe-asia' },
  { name: 'fas fa-globe-europe' },
  { name: 'fas fa-golf-ball' },
  { name: 'fas fa-gopuram' },
  { name: 'fas fa-graduation-cap' },
  { name: 'fas fa-greater-than' },
  { name: 'fas fa-greater-than-equal' },
  { name: 'fas fa-grimace' },
  { name: 'fas fa-grin' },
  { name: 'fas fa-grin-alt' },
  { name: 'fas fa-grin-beam' },
  { name: 'fas fa-grin-beam-sweat' },
  { name: 'fas fa-grin-hearts' },
  { name: 'fas fa-grin-squint' },
  { name: 'fas fa-grin-squint-tears' },
  { name: 'fas fa-grin-stars' },
  { name: 'fas fa-grin-tears' },
  { name: 'fas fa-grin-tongue' },
  { name: 'fas fa-grin-tongue-squint' },
  { name: 'fas fa-grin-tongue-wink' },
  { name: 'fas fa-grin-wink' },
  { name: 'fas fa-grip-horizontal' },
  { name: 'fas fa-grip-lines' },
  { name: 'fas fa-grip-lines-vertical' },
  { name: 'fas fa-grip-vertical' },
  { name: 'fas fa-guitar' },
  { name: 'fas fa-h-square' },
  { name: 'fas fa-hamburger' },
  { name: 'fas fa-hammer' },
  { name: 'fas fa-hamsa' },
  { name: 'fas fa-hand-holding' },
  { name: 'fas fa-hand-holding-heart' },
  { name: 'fas fa-hand-holding-usd' },
  { name: 'fas fa-hand-lizard' },
  { name: 'fas fa-hand-middle-finger' },
  { name: 'fas fa-hand-paper' },
  { name: 'fas fa-hand-peace' },
  { name: 'fas fa-hand-point-down' },
  { name: 'fas fa-hand-point-left' },
  { name: 'fas fa-hand-point-right' },
  { name: 'fas fa-hand-point-up' },
  { name: 'fas fa-hand-pointer' },
  { name: 'fas fa-hand-rock' },
  { name: 'fas fa-hand-scissors' },
  { name: 'fas fa-hand-spock' },
  { name: 'fas fa-hands' },
  { name: 'fas fa-hands-helping' },
  { name: 'fas fa-handshake' },
  { name: 'fas fa-hanukiah' },
  { name: 'fas fa-hard-hat' },
  { name: 'fas fa-hashtag' },
  { name: 'fas fa-hat-wizard' },
  { name: 'fas fa-haykal' },
  { name: 'fas fa-hdd' },
  { name: 'fas fa-heading' },
  { name: 'fas fa-headphones' },
  { name: 'fas fa-headphones-alt' },
  { name: 'fas fa-headset' },
  { name: 'fas fa-heart' },
  { name: 'fas fa-heart-broken' },
  { name: 'fas fa-heartbeat' },
  { name: 'fas fa-helicopter' },
  { name: 'fas fa-highlighter' },
  { name: 'fas fa-hiking' },
  { name: 'fas fa-hippo' },
  { name: 'fas fa-history' },
  { name: 'fas fa-hockey-puck' },
  { name: 'fas fa-holly-berry' },
  { name: 'fas fa-home' },
  { name: 'fas fa-horse' },
  { name: 'fas fa-horse-head' },
  { name: 'fas fa-hospital' },
  { name: 'fas fa-hospital-alt' },
  { name: 'fas fa-hospital-symbol' },
  { name: 'fas fa-hot-tub' },
  { name: 'fas fa-hotdog' },
  { name: 'fas fa-hotel' },
  { name: 'fas fa-hourglass' },
  { name: 'fas fa-hourglass-end' },
  { name: 'fas fa-hourglass-half' },
  { name: 'fas fa-hourglass-start' },
  { name: 'fas fa-house-damage' },
  { name: 'fas fa-hryvnia' },
  { name: 'fas fa-i-cursor' },
  { name: 'fas fa-ice-cream' },
  { name: 'fas fa-icicles' },
  { name: 'fas fa-icons' },
  { name: 'fas fa-id-badge' },
  { name: 'fas fa-id-card' },
  { name: 'fas fa-id-card-alt' },
  { name: 'fas fa-igloo' },
  { name: 'fas fa-image' },
  { name: 'fas fa-images' },
  { name: 'fas fa-inbox' },
  { name: 'fas fa-indent' },
  { name: 'fas fa-industry' },
  { name: 'fas fa-infinity' },
  { name: 'fas fa-info' },
  { name: 'fas fa-info-circle' },
  { name: 'fas fa-italic' },
  { name: 'fas fa-jedi' },
  { name: 'fas fa-joint' },
  { name: 'fas fa-journal-whills' },
  { name: 'fas fa-kaaba' },
  { name: 'fas fa-key' },
  { name: 'fas fa-keyboard' },
  { name: 'fas fa-khanda' },
  { name: 'fas fa-kiss' },
  { name: 'fas fa-kiss-beam' },
  { name: 'fas fa-kiss-wink-heart' },
  { name: 'fas fa-kiwi-bird' },
  { name: 'fas fa-otter' },
  { name: 'fas fa-paw' },
  { name: 'fas fa-spider' },
];

export default icons;
