import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  addSubform(event) {
    let newAssetRow = document.createElement('tr');
    newAssetRow.setAttribute('class', 'asset_row');
    newAssetRow.innerHTML = document.querySelector('#new_asset_row').cloneNode(true).innerHTML + " ";

    let newIndex = document.querySelectorAll('.asset_row').length;
    newAssetRow.innerHTML = newAssetRow.innerHTML.replace(/NEWINDEX/g, newIndex);

    this.element.querySelector('.asset_rows').appendChild(newAssetRow);
  }
}

