import {Controller} from "@hotwired/stimulus"

/**
 * Manages the <code>aria-expanded</code> attribute of an element and toggles an <code>expanded</code> class
 * on its controlled element.
 */
export default class extends Controller {
    connect() {
        this.controllerTarget = this.element;
        if (!this.controllerTarget.hasAttribute('aria-expanded')) {
            this.controllerTarget.setAttribute('aria-expanded', 'false');
        }

        let ariaControlsId = this.controllerTarget.getAttribute('aria-controls');
        if (!ariaControlsId) {
            throw new Error('aria-expanded-controller requires an aria-controls attribute');
        }

        this.controlledTarget = document.getElementById(ariaControlsId);
        if (!this.controlledTarget) {
            throw new Error(`Could not find element with id ${ariaControlsId}`);
        }
    }

    collapse() {
        this.controlledTarget.classList.remove('expanded');
        this.controllerTarget.setAttribute('aria-expanded', false);
    }

    expand() {
        this.controlledTarget.classList.add('expanded');
        this.controllerTarget.setAttribute('aria-expanded', true);
    }

    toggle(event) {
        // debounce to avoid repeat toggles (e.g., from mobile taps or double-clicks).
        if (this.isToggling) {
            return;
        }
        this.isToggling = true;

        let isExpanded = this.controllerTarget.getAttribute('aria-expanded') === 'true';
        isExpanded ? this.collapse() : this.expand();

        if (event && event.type === 'click') {
            event.preventDefault();
            event.stopPropagation();
        }

        setTimeout(() => {
            this.isToggling = false;
        }, 100);
    }
}

