
import { Controller } from '@hotwired/stimulus';
import "@hotwired/turbo";

export default class extends Controller {
  static targets = ["form"];
  hasUnsavedChanges = false;

  initialize() {
    this.previewUrl = this.formTarget.getAttribute('action');
    this.setupEventListeners();
  }

  connect() {
    // Bind the methods and store the references
    this.boundHandleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.boundHandleTurboBeforeVisit = this.handleTurboBeforeVisit.bind(this);

    // Add event listeners using the bound references
    window.addEventListener('beforeunload', this.boundHandleBeforeUnload);
    document.addEventListener('turbo:before-visit', this.boundHandleTurboBeforeVisit);
  }

  setupEventListeners() {
    document.addEventListener('updateGalleryPreview', (event) => {
      this.handleSubmit(event);
    });

    this.element.querySelectorAll('.link_card').forEach(linkCard => {
      this.registerForChangeDetection(linkCard);
    });

    document.addEventListener('sortStopped', this.updatePositions.bind(this));

    this.formTarget.querySelector('#links-save-button').addEventListener('click', (event) => {
      this.hasUnsavedChanges = false;
    });
  
    this.formTarget.querySelector('#links-cancel-link').addEventListener('click', (event) => {
      this.hasUnsavedChanges = false;
    });

  }

  updatePositions() {
    document.querySelectorAll('.link_card .position').forEach((linkPosition, index) => {
      linkPosition.setAttribute('value', index);
    });
    document.dispatchEvent(new CustomEvent('updateGalleryPreview'));
  }

  addSubform(event) {
    let newLinkCard = document.createElement('div');
    newLinkCard.innerHTML = this.element.querySelector('#new_link_card').cloneNode(true).innerHTML;
    newLinkCard = newLinkCard.querySelector('.link_card');
    newLinkCard.innerHTML = newLinkCard.innerHTML + " ";

    let newIndex = this.element.querySelectorAll('.link_card').length;
    newLinkCard.innerHTML = newLinkCard.innerHTML.replace(/NEWINDEX/g, newIndex);

    this.element.querySelector('.all_links').appendChild(newLinkCard);
    this.registerForChangeDetection(newLinkCard);
  
    document.dispatchEvent(new CustomEvent('updateGalleryPreview'));
  }

  registerForChangeDetection(linkCard) {
    // Bind event listeners to all input, select, and textarea elements within the link card
    linkCard.querySelectorAll('input, select, textarea').forEach(element => {
      element.addEventListener('change', (event) => this.handleSubmit(event));
    });
  
    // If you have specific logic for text inputs or other types of fields, add here
    linkCard.querySelectorAll('input[type="text"], textarea').forEach(textInput => {
      textInput.addEventListener('input', (event) => this.handleSubmit(event));
    });
  }
  
  handleSubmit(event) {
    event.preventDefault();
    
    const formSubmitUrl = this.formTarget.getAttribute('data-preview-url');
  
    this.formTarget.action = formSubmitUrl;

    this.formTarget.setAttribute('target', 'galleryPreviewIframe');
    this.formTarget.submit();

    // Update the hidden iframe
    this.formTarget.setAttribute('target', 'web-iframe')
    this.formTarget.submit();

    this.revertForm();
    this.hasUnsavedChanges = true;
  }

  revertForm() {
    const originalFormUrl = this.formTarget.getAttribute('data-form-submit-url');
    this.formTarget.action = originalFormUrl 
  
    // Remove the 'target' attribute
    this.formTarget.removeAttribute('target');
  }

  handleBeforeUnload(event) {
    if (this.hasUnsavedChanges) {
      event.preventDefault();
      event.returnValue = "Unsaved changes will be lost if you leave this page. Do you wish to continue?";

      setTimeout(() => {
        if (!document.hidden) { // Check if the document is still visible
          this.hasUnsavedChanges = false; // User stayed on the page, reset flag
        }
      }, 0);
    }
  }

  handleTurboBeforeVisit(event) {
    if (this.hasUnsavedChanges) {
      const confirmationMessage = "Unsaved changes will be lost if you leave this page. Do you wish to continue?";
      if (!confirm(confirmationMessage)) {
        // User clicked "Cancel", prevent navigation
        event.preventDefault();
      } else {
        // User clicked "OK", allow navigation
        this.hasUnsavedChanges = false;
      }
    }
  }

  disconnect() {
    // Remove the event listeners using the stored references
    window.removeEventListener('beforeunload', this.boundHandleBeforeUnload);
    document.removeEventListener('turbo:before-visit', this.boundHandleTurboBeforeVisit);
  }
}
