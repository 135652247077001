import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'input'];

  connect() {
    this.updatePlaceholder();
  }

  handleChange() {
    this.updatePlaceholder();
  }

  updatePlaceholder() {
    let placeholderValue;

    switch (this.selectTarget.value) {
      case 'email':
        placeholderValue = 'test@example.com';
        break;
      case 'phone':
      case 'text':
        placeholderValue = '555-555-5555';
        break;
      case 'facebook':
        placeholderValue = 'myFacebookHandle';
        break;
      case 'instagram':
        placeholderValue = 'myInstagramHandle';
        break;
      case 'youtube':
        placeholderValue = '@myYoutubeHandle';
        break;
      case 'x':
        placeholderValue = 'myTwitterHandle';
        break;
      case 'tiktok':
        placeholderValue = "myTikTokHandle";
        break;
      default:
        placeholderValue = '(your contact info here)';
    }

    this.inputTarget.placeholder = placeholderValue;
  }
}
