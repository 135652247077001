import { Controller } from '@hotwired/stimulus';
import "@hotwired/turbo";

export default class extends Controller {
  static targets = ["form"];
  hasUnsavedChanges = false;

  initialize() {
    this.setupEventListeners();
  }

  connect() {
    // Bind the methods and store the references
    this.boundHandleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.boundHandleTurboBeforeVisit = this.handleTurboBeforeVisit.bind(this);

    // Add event listeners using the bound references
    window.addEventListener('beforeunload', this.boundHandleBeforeUnload);
    document.addEventListener('turbo:before-visit', this.boundHandleTurboBeforeVisit);
  }

  setupEventListeners() {

    this.formTarget.querySelectorAll('input, select, textarea').forEach(input => {
      input.addEventListener('change', this.handleSubmitOnChange.bind(this));
    });

    this.formTarget.querySelector('#appearance_save_button').addEventListener('click', (event) => {
      this.hasUnsavedChanges = false;
      
      setTimeout(() => {
        location.reload();
      }, 1000); 
    });
    this.formTarget.querySelector('#appearance_reset_link').addEventListener('click', () => this.hasUnsavedChanges = false); 
    this.formTarget.querySelector('#appearance_cancel_link').addEventListener('click', () => this.hasUnsavedChanges = false); 
  }
  
  handleSubmitOnChange(event) {
    event.preventDefault();
  
    const formSubmitUrl = this.formTarget.getAttribute('data-preview-url');
  
    this.formTarget.action = formSubmitUrl;
    this.formTarget.method = 'post';
  
    // Remove or modify the hidden _method input
    const methodInput = this.formTarget.querySelector('input[name="_method"]');
    if (methodInput) {
      methodInput.value = 'post'; 
    }

    // Set the target to the visible iframe (galleryPreviewIframe)
    this.formTarget.setAttribute('target', 'galleryPreviewIframe');
    this.formTarget.submit();

    // Update the hidden iframe
    this.formTarget.setAttribute('target', 'web-iframe')
    this.formTarget.submit();

    this.revertForm();
    this.hasUnsavedChanges = true;
  }

  revertForm() {
    const originalFormUrl = this.formTarget.getAttribute('data-form-submit-url');
    this.formTarget.action = originalFormUrl 
  
    // Reset the form's method to 'PATCH'
    this.formTarget.method = 'patch';

    // Reset the hidden _method input to 'patch', if it exists
    const methodInput = this.formTarget.querySelector('input[name="_method"]');
    if (methodInput) {
      methodInput.value = 'patch';
    }
  
    // Remove the 'target' attribute
    this.formTarget.removeAttribute('target');
  }

  handleBeforeUnload(event) {
    if (this.hasUnsavedChanges) {
      event.preventDefault();
      event.returnValue = "Unsaved changes will be lost if you leave this page. Do you wish to continue?";

      setTimeout(() => {
        if (!document.hidden) { // Check if the document is still visible
          this.hasUnsavedChanges = false; // User stayed on the page, reset flag
        }
      }, 0);
    }
  }

  handleTurboBeforeVisit(event) {
    if (this.hasUnsavedChanges) {
      const confirmationMessage = "Unsaved changes will be lost if you leave this page. Do you wish to continue?";
      if (!confirm(confirmationMessage)) {
        // User clicked "Cancel", prevent navigation
        event.preventDefault();
      } else {
        // User clicked "OK", allow navigation
        this.hasUnsavedChanges = false;
      }
    }
  }

  disconnect() {
    // Remove the event listeners using the stored references
    window.removeEventListener('beforeunload', this.boundHandleBeforeUnload);
    document.removeEventListener('turbo:before-visit', this.boundHandleTurboBeforeVisit);
  }

}
