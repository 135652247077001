import { application } from "./application";

import ArchiveSearchBoxController from "./archive_search_box_controller.js";
application.register("archive-search-box", ArchiveSearchBoxController);

import AriaExpandedController from "./aria_expanded_controller.js";
application.register("aria-expanded", AriaExpandedController);

import AssetsFormController from "./assets_form_controller.js";
application.register("assets-form", AssetsFormController);

import CarouselController from "./carousel_controller.js";
application.register("carousel", CarouselController);

import Chartjs from "@stimulus-components/chartjs";
application.register('chart', Chartjs);

import ChoiceController from "./choice_controller.js";
application.register("choice", ChoiceController);

import CloneTemplateController from "./clone_template_controller.js";
application.register("clone-template", CloneTemplateController);

import CloseWindowController from "./close_window_controller.js";
application.register("close-window", CloseWindowController);

import ColorPickerController from "./color_picker_controller.js";
application.register("color-picker", ColorPickerController);

import CopyOnClickController from "./copy_on_click_controller.js";
application.register("copy-on-click", CopyOnClickController);

import DelegateClickController from "./delegate_click_controller.js";
application.register("delegate-click", DelegateClickController);

import EmojiPickerController from "./emoji_picker_controller.js";
application.register("emoji-picker", EmojiPickerController);

import ExpanderController from "./expander_controller.js";
application.register("expander", ExpanderController);

import FiltersCarouselController from "./filters_carousel_controller.js";
application.register("filters-carousel", FiltersCarouselController);

import FlashController from "./flash_controller.js";
application.register("flash", FlashController);

import FlatpickrController from "./flatpickr_controller";
application.register("flatpickr", FlatpickrController);

import FormController from "./form_controller.js";
application.register("form", FormController);

import FormPreviewController from "./form_preview_controller";
application.register("form-preview", FormPreviewController);

import GalleryPostFiltersController from "./gallery_post_filters_controller.js";
application.register("gallery-post-filters", GalleryPostFiltersController);

import HideController from "./hide_controller.js";
application.register("hide", HideController);

import IconPickerController from "./icon_picker_controller.js";
application.register("icon-picker", IconPickerController);

import InfiniteScrollController from "./infinite_scroll_controller.js";
application.register("infinite-scroll", InfiniteScrollController);

import InitiateSocialOauthController from "./initiate_social_oauth_controller.js";
application.register("initiate-social-oauth", InitiateSocialOauthController);

import InlineCopyButtonController from "./inline_copy_button_controller.js";
application.register("inline-copy-button", InlineCopyButtonController);

import InviteLinkFlashController from "./invite_link_flash_controller.js";
application.register("invite-link-flash", InviteLinkFlashController);

import LightboxController from "./lightbox_controller.js";
application.register("lightbox", LightboxController);

import LinkImagePickerController from "./link_image_picker_controller.js";
application.register("link-image-picker", LinkImagePickerController);

import LinksFormController from "./links_form_controller.js";
application.register("links-form", LinksFormController);

import MasonryController from "./masonry_controller.js";
application.register("masonry", MasonryController);

import MenuController from "./menu_controller.js";
application.register("menu", MenuController);

import MinimalPackeryController from "./minimal_packery_controller.js";
application.register("minimal-packery", MinimalPackeryController);

import OriginalCarouselController from "./original_carousel_controller.js";
application.register("original-carousel", OriginalCarouselController);

import PostFullscreenController from "./post_fullscreen_controller.js";
application.register("post-fullscreen", PostFullscreenController);

import PostNavController from "./post_nav_controller.js";
application.register("post-nav", PostNavController);

import PrimaryCarouselController from "./primary_carousel_controller.js";
application.register("primary-carousel", PrimaryCarouselController);

import ProfileSectionFormController from "./profile_section_form_controller.js";
application.register("profile-section-form", ProfileSectionFormController);

import RadioLabelController from "./radio_label_controller.js";
application.register("radio-label", RadioLabelController);

import ScrollAnimationController from "./scroll_animation_controller.js";
application.register("scroll-animation", ScrollAnimationController);

import ScrollFollowController from "./scroll_follow_controller.js";
application.register("scroll-follow", ScrollFollowController);

import SectionsSortController from "./sections_sort_controller.js";
application.register("sections-sort", SectionsSortController);

import SelectAsyncController from "./select_async_controller";
application.register("select-async", SelectAsyncController);

import SelectGroupsController from "./select_groups_controller.js";
application.register("select-groups", SelectGroupsController);

import SelectProfilesController from "./select_profiles_controller.js";
application.register("select-profiles", SelectProfilesController);

import SelectTagsController from "./select_tags_controller.js";
application.register("select-tags", SelectTagsController);

import ShowFaqAnswerController from "./show_faq_answer_controller.js";
application.register("show-faq-answer", ShowFaqAnswerController);

import ShowMoreController from "./show_more_controller.js";
application.register("show-more", ShowMoreController);

import SortPriorityController from "./sort_priority_controller.js";
application.register("sort-priority", SortPriorityController);

import TabpanelController from "./tabpanel_controller.js";
application.register("tabpanel", TabpanelController);

import TinymceController from "./tinymce_controller.js";
application.register("tinymce", TinymceController);

import TruncatePostTextController from "./truncate_post_text_controller.js";
application.register("truncate-post-text", TruncatePostTextController);

import TruncateTextController from "./truncate_text_controller.js";
application.register("truncate-text", TruncateTextController);

import TurboModalController from "./turbo_modal_controller.js";
application.register("turbo-modal", TurboModalController);

import UnhideController from "./unhide_controller.js";
application.register("unhide", UnhideController);

import ToggleFieldController from "./toggle_field_controller.js";
application.register("toggle-field", ToggleFieldController);

import ContactPlaceholderController from "./contact_placeholder_controller.js";
application.register("contact-placeholder", ContactPlaceholderController);

import DeleteController from "./delete_controller.js";
application.register("delete", DeleteController);

import InvalidUrlController from "./invalid_url_controller.js";
application.register("invalid-url", InvalidUrlController);

import SelectBrandsController from "./select_brands_controller.js";
application.register("select-brands", SelectBrandsController);

import SelectBrandOptionsController from "./select_brand_options_controller.js";
application.register("select-brand-options", SelectBrandOptionsController);

import SelectSyndicationAccountsController from "./select_syndication_accounts_controller.js";
application.register("select-syndication-accounts", SelectSyndicationAccountsController);

import AppearanceFormController from "./appearance_form_controller.js";
application.register("appearance-form", AppearanceFormController);

import FeaturedFormController from "./featured_form_controller.js";
application.register("featured-form", FeaturedFormController);

import FaqFormController from "./faq_form_controller.js";
application.register("faq-form", FaqFormController);

import FunFactFormController from "./fun_fact_form_controller.js";
application.register("fun-fact-form", FunFactFormController);

import BrandFormController from "./brand_form_controller.js";
application.register("brand-form", BrandFormController);

import ContactFormController from "./contact_form_controller.js";
application.register("contact-form", ContactFormController);

import IframeScrollController from "./iframe_scroll_controller.js";
application.register("iframe-scroll", IframeScrollController);

import SelectAllContentController from "./select_all_content_controller.js";
application.register("select-all-content", SelectAllContentController);

import ContentFilterModalController from "./content_filter_modal_controller.js";
application.register("content-filter-modal", ContentFilterModalController);

import AudienceController from "./audience_controller.js";
application.register("audience", AudienceController);

import PriceController from "./price_controller.js";
application.register("price", PriceController);

import InputLengthController from "./input_length_controller.js";
application.register("input-length", InputLengthController);

import WebGalleryPreviewModalController from "./web_gallery_preview_modal_controller.js";
application.register("web-gallery-preview-modal", WebGalleryPreviewModalController);
