import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["answer", "icon"];

  toggleAnswer() {
    this.answerTarget.classList.toggle('hidden');

    if (this.iconTarget.classList.contains("fa-chevron-down")) {
      this.iconTarget.classList.remove("fa-chevron-down");
      this.iconTarget.classList.add("fa-chevron-up");
    } else {
      this.iconTarget.classList.remove("fa-chevron-up");
      this.iconTarget.classList.add("fa-chevron-down");
    }
  }
}
