import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["gridView", "listView", "iconButton", "checkbox", "searchInput", "clear", "reset"]

  connect() {
    this.toggleView = this.toggleView.bind(this);
    this.toggleAccount = this.toggleAccount.bind(this);

    this.updateButtonClasses();
  }

  toggleView(e) {
    this.gridViewTarget.classList.toggle('unselected');
    this.listViewTarget.classList.toggle('unselected');

    const viewType = e.currentTarget === this.listViewTarget ? 'list' : 'grid';
    const customEvent = new CustomEvent('viewTypeChanged', { detail: { viewType } });
    document.dispatchEvent(customEvent);
  }

  toggleAccount(event) {

    event.currentTarget.classList.toggle('unselected');

    let source = event.currentTarget.dataset.source;
    let sourceList = JSON.parse(sessionStorage.getItem('selectedSources')) || [];

    if (event.currentTarget.classList.contains('unselected')) {
      // Add source from sourceList
      sourceList.push(source);
    } else {
      // Remove source to sourceList
      sourceList = sourceList.filter(item => item !== source);
    }
    sessionStorage.setItem('selectedSources', JSON.stringify(sourceList));

    // Fetch new posts after updating sourceList
    this.fetchPosts();
  }

  fetchPosts() {
    let sourceList = JSON.parse(sessionStorage.getItem('selectedSources')) || [];
    let profileUrl = window.location.pathname;

    let search = sessionStorage.getItem('galleryInputFilter');

    if (search) {
      window.location.href = `${profileUrl}?search=${search}&sources=${JSON.stringify(sourceList)}`;
    } else {
      window.location.href = `${profileUrl}?sources=${JSON.stringify(sourceList)}`;
    }
  }

  updateButtonClasses() {
    let sourceList = JSON.parse(sessionStorage.getItem('selectedSources')) || [];

    let iconButtons = document.querySelectorAll('.icon');

    iconButtons.forEach(button => {
      let source = button.dataset.source;
      if (sourceList.includes(source)) {
        button.classList.add('unselected');
      } else {
        button.classList.remove('unselected');
      }
    });
  }

  sortBy(event) {
    let search = sessionStorage.getItem('galleryInputFilter');
    let sourceList = JSON.parse(sessionStorage.getItem('selectedSources')) || [];
    let sortById = event.currentTarget.id;
    let sortList = JSON.parse(sessionStorage.getItem('sortBy')) || [];

    if(!event.currentTarget.checked){
      // Checkbox has been unchecked, add to array if it's not already there
      if(!sortList.includes(sortById)){
        sortList.push(sortById);
      }
    } else {
      // Checkbox has been checked, remove from array
      sortList = sortList.filter(id => id !== sortById);
    }

    sessionStorage.setItem('sortBy', JSON.stringify(sortList));

    let profileUrl = window.location.pathname;

    // Base URL is always there
    let newUrl = `${profileUrl}?`;

     // If sortList is not empty, add it to the url
     if (sortList.length > 0) {
      newUrl += `sortBy=${JSON.stringify(sortList)}&`;
    }

    // If search exists, add it to the url
    if (search) {
      newUrl += `search=${search}&`;
    }

    // If source exists, add it to the url
    if (sourceList) {
      newUrl += `sources=${JSON.stringify(sourceList)}&`;
    }

    // Remove the trailing "&" if present
    newUrl = newUrl.endsWith("&") ? newUrl.slice(0, -1) : newUrl;

    window.location.href = newUrl;
  }

  resetPosts() {
    this.resetTarget.classList.add('hidden')
    sessionStorage.removeItem('galleryInputFilter');
    sessionStorage.setItem('gallerySearchReset', 'false')
    sessionStorage.removeItem('selectedSources')

    let profileUrl = window.location.pathname;
    window.location.href = `${profileUrl}`
  }

  revealClear(event) {
    if (this.searchInputTarget.value) {
      this.clearTarget.classList.remove('hidden')
      if (event.key === "Enter") {
        let search = this.searchInputTarget.value;
        sessionStorage.setItem('galleryInputFilter', search);

        let profileUrl = window.location.pathname;

        let sourceList = JSON.parse(sessionStorage.getItem('selectedSources')) || [];

        window.location.href = `${profileUrl}?search=${search}&sources=${JSON.stringify(sourceList)}`;
        sessionStorage.setItem('gallerySearchReset', 'true');
      }
    } else {
      this.clearTarget.classList.add('hidden')
    }
  }

  clearInput() {
    this.searchInputTarget.value = '';
    this.clearTarget.classList.add('hidden')
  }
}
