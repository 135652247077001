import { Controller } from '@hotwired/stimulus';
import "@hotwired/turbo";

/**
 * Clones a template and appends its content as a child to the container.
 * The template is expected to have a single root-level element (e.g., `<template><div>...</div></template>`).
 */
export default class extends Controller {
    static targets = ["template", "container"];

    clone(event) {
        // make a copy of the template's content.
        let newElement = document.createElement('div');
        newElement.innerHTML = this.templateTarget.cloneNode(true).innerHTML;
        newElement = newElement.children[0];
        newElement.innerHTML = newElement.innerHTML + " ";

        // we can use this controller for subform management (adding new items to a list).
        // in that case, we need to update the index of the new item to make simple_form submissions work as expected.
        let newIndex = this.containerTarget.children.length;
        newElement.innerHTML = newElement.innerHTML.replace(/NEWINDEX/g, newIndex);

        // add the cloned template to the container as its last child.
        this.containerTarget.appendChild(newElement);
    }
}

