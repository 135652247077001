import { Controller } from '@hotwired/stimulus';
import "@hotwired/turbo";

export default class extends Controller {
  static targets = ["card"];

  initialize() {
    this.element.querySelectorAll('.section-container').forEach(sectionCard => {
      this.registerForChangeDetection(sectionCard);
    });

    document.addEventListener('sortStopped', () => {
      this.cardTargets.forEach((sectionCard, index) => {
        const position = sectionCard.querySelector('.position');
        const containerType = sectionCard.dataset.sectionType;
    
        if (containerType === "featured") {
          position.setAttribute('value', index);
    
          const aboutSectionCard = this.cardTargets.find(card => card.dataset.sectionType === 'about');
          const aboutPosition = aboutSectionCard.querySelector('.position');
          aboutPosition.setAttribute('value', index + 1);
    
        } else if (containerType !== "about") { // Ensure 'about' section not rendered twice
          position.setAttribute('value', index);
        }
      });
      this.submitForm();
      this.reloadIframe();
    });
  }

  registerForChangeDetection(sectionCard) {
    sectionCard.querySelectorAll('input').forEach(input => {
      input.addEventListener('change', () => {
        this.submitForm();
        this.reloadIframe();
      });
    });
  }

  reloadIframe() {
    setTimeout(() => {
      const iframe = document.getElementById('galleryPreviewIframe');
      const hiddenIframe = document.getElementById('web-iframe')
      if (iframe && hiddenIframe) {
        // Reload iframe content
        iframe.contentWindow.location.reload();
        hiddenIframe.contentWindow.location.reload();
      }
    }, 500);
  }

  submitForm() {
    const form = this.element.closest('turbo-frame').querySelector('form');
    if (form) {
      form.requestSubmit(); // Automatically submits the form
    }
  }
}
