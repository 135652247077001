//******* ADD THIS CONTROLLER BACK IF USING WEB-PREVIEW *********/
//** commented out because the button to show the preview (webLink) was removed and thus threw an error **/


// import { Controller } from '@hotwired/stimulus'

// export default class extends Controller {
//   static targets = ["modal", "preview", "webLink", "close"];


//   connect() {
//     this.openHandler = this.open.bind(this);
//     this.closeHandler = this.close.bind(this);
//     this.handleClickOutside = this.handleClickOutside.bind(this);
//     this.handleEscape = this.handleEscape.bind(this);

//     this.webLinkTarget.addEventListener('click', this.openHandler);
//     this.closeTarget.addEventListener('click', this.closeHandler);
//     window.addEventListener('click', this.handleClickOutside);
//     document.addEventListener('keydown', this.handleEscape);
//   }

//   open(event) {
//     event.preventDefault();
//     this.modalTarget.style.display = "block";
//   }

//   close() {
//     this.modalTarget.style.display = "none";
//   }

//   handleClickOutside(event) {
//     // Check if the clicked element is the link that opens the modal
//     if (this.webLinkTarget.contains(event.target)) {
//       return; // Do nothing if the link is clicked
//     }
  
//     let modalContent = this.modalTarget.querySelector('.web-modal-content')

//     // Check if the click is outside the modal and the modal is open
//     if (!modalContent.contains(event.target) && this.modalTarget.style.display === "block") {
//       this.close();
//     }
//   }

//   handleEscape(event) {
//     if (event.key === 'Escape') {
//       this.close();
//     }
//   }

//   disconnect() {
//     this.webLinkTarget.removeEventListener('click', this.openHandler);
//     this.closeTarget.removeEventListener('click', this.closeHandler);
//     window.removeEventListener('click', this.handleClickOutside);
//     document.removeEventListener('keydown', this.handleEscape);
//   }
// }
