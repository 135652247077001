import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "nub"]

  connect() {
    if (this.hasContainerTarget && this.hasNubTarget) {
      let containerRect = this.containerTarget.getBoundingClientRect();
      let startOffset = containerRect.top + window.scrollY;
      let endOffset = startOffset + this.containerTarget.offsetHeight - (this.nubTarget.offsetHeight / 2);

      window.addEventListener("scroll", () => {
        this.handleScroll(containerRect, startOffset, endOffset)
      })
    }
  }

  handleScroll(containerRect, startOffset, endOffset) {
    let minTop = 55;
    let maxTop = containerRect.clientHeight - 100;
    let scrollPosition = window.scrollY

    if (scrollPosition <= startOffset) {
      this.nubTarget.style.top = `${minTop}px`;
    } else if (scrollPosition >= endOffset) {
      let newTop = Math.max(containerRect.offsetHeight - this.nubTarget.offsetHeight, minTop);

      if (newTop > maxTop) {
        newTop = maxTop
      }

      this.nubTarget.style.top = `${newTop}px`;
    } else {
      let newTop = Math.max(scrollPosition - startOffset, minTop);

      if (newTop > maxTop) {
        newTop = maxTop
      }

      this.nubTarget.style.top = `${newTop}px`;
    }
  }
}