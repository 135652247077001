import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["filterSyndicationAccountName", "loadSyndicationAccountLink", "selectSyndicationAccountId"]

  initialize() {
    this.filterSyndicationAccountNameTarget.value = "";

    this.selectSyndicationAccountIdTarget.addEventListener("change", this.selectSyndicationAccount.bind(this));
    this.selectSyndicationAccountIdTarget.addEventListener("click", this.selectSyndicationAccount.bind(this));
    if (this.selectSyndicationAccountIdTarget.value) {
      this.selectSyndicationAccount();
    }
  }

  filterSyndicationAccounts() {
    let filterName = this.filterSyndicationAccountNameTarget.value;
    let syndicationAccountOptions = this.selectSyndicationAccountIdTarget.options;

    for (let i = 0; i < syndicationAccountOptions.length; i++) {
      let syndicationAccountOption = syndicationAccountOptions[i];
      let syndicationAccountName = syndicationAccountOption.innerText;
      if (syndicationAccountName.toLowerCase().includes(filterName.toLowerCase())) {
        syndicationAccountOption.style.display = 'block';
      } else {
        syndicationAccountOption.style.display = 'none';
      }
    }
  }

  selectSyndicationAccount() {
    const syndicationAccountId = this.selectSyndicationAccountIdTarget.value;

    if (syndicationAccountId) {
      this.loadSyndicationAccountLinkTarget.href = `/admin/syndication_accounts/${syndicationAccountId}`;
      this.loadSyndicationAccountLinkTarget.click();
    }
  }
}
