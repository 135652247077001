import { Controller } from '@hotwired/stimulus';
import Packery from 'packery';

export default class extends Controller {
  connect() {
    this.packeryGrid = new Packery('.gallery-grid', {
        itemSelector: '.gallery-item',
        columnWidth: '.grid-sizer',
        rowHeight: 1
      }
    )
  }
}
