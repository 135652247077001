import { Controller } from '@hotwired/stimulus';
import icons from "../packs/font_awesome_icons";

export default class extends Controller {
  static targets = ["input", "button", "dropdown", "filter", "icon"]

  connect() {
    document.addEventListener('mousedown', (event) => {
      if (!this.element.contains(event.target)) {
        this.hide();
      }
    });

    this.filterTarget.addEventListener('input', (event) => {
        this.filter(event);
    });

    this.updateDropdown();
  }

  filter(event) {
    const filterString = this.filterTarget.value.toLowerCase();
    const buttons = this.dropdownTarget.querySelectorAll('.wrapper .icon_dropdown_menu [data-icon-picker-target="icon"]');
    buttons.forEach((button) => {
      const name = button.getAttribute("data-icon-name");
      if (name.toLowerCase().indexOf(filterString) > -1) {
        button.style.display = "";
      } else {
        button.style.display = "none";
      }
    });
  }

  updateDropdown() {
    const dropdown = this.dropdownTarget;
    dropdown.querySelector('.wrapper .icon_dropdown_menu').innerHTML = icons
      .map(
        (icon) => `<button 
        type="button" 
        class="block px-4 py-2 text-gray-800 hover:bg-gray-200" 
        data-icon-picker-target="icon" 
        data-action="icon-picker#selectIcon"
        data-icon-name="${icon.name}">
                     <i class="${icon.name}"></i>
                   </button>`
      )
      .join("");
  }

  toggle() {
    this.dropdownTarget.classList.toggle('hidden');
  }

  hide() {
    this.dropdownTarget.classList.add("hidden");
  }

  selectIcon(event) {
    const iconName = event.currentTarget.getAttribute("data-icon-name");
    this.inputTarget.value = iconName;
    this.buttonTarget.querySelector('i').setAttribute('class', 'fa-fw ' + iconName);
    this.filterTarget.value = "";
    this.filter();
    this.hide();

    document.dispatchEvent(new CustomEvent('updateGalleryPreview'));
  }
}
