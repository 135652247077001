import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ["filterCategory", "filterProfileName", "loadProfileLink", "selectProfileId"]

    initialize() {
        this.filterCategoryTarget.value = "";
        this.filterProfileNameTarget.value = "";

        this.filterCategoryTarget.addEventListener("change", this.filterProfiles.bind(this));
        this.filterProfileNameTarget.addEventListener("input", this.filterProfiles.bind(this));

        this.selectProfileIdTarget.addEventListener("change", this.selectProfile.bind(this));
        this.selectProfileIdTarget.addEventListener("click", this.selectProfile.bind(this));
        if(this.selectProfileIdTarget.value) {
            this.selectProfile();
        }
    }

    filterProfiles() {
        let filterCategory = this.filterCategoryTarget.value;
        let filterName = this.filterProfileNameTarget.value;
        let profileOptions = this.selectProfileIdTarget.options;
        for(let i = 0; i < profileOptions.length; i++) {
            let profileOption = profileOptions[i];
            let profileCategory = profileOption.dataset.categoryId;
            let profileName = profileOption.innerText;
            if(filterCategory === "" || filterCategory === profileCategory) {
                if(profileName.toLowerCase().includes(filterName.toLowerCase())) {
                    profileOption.style.display = 'block';
                } else {
                    profileOption.style.display = 'none';
                }
            } else {
                profileOption.style.display = 'none';
            }
        }
    }

    selectProfile() {
        const pathOptions = [
            'appearance',
            'background_status',
            'diagnostics',
            'edit',
            'links',
            'log',
            'posts',
            'slice_meter'
        ];

        let desiredPath = 'edit'
        let lastPathSection = window.location.pathname.split('/').pop();
        if(pathOptions.includes(lastPathSection)) {
            desiredPath = lastPathSection;
        }

        let profileId = this.selectProfileIdTarget.value;
        if(profileId) {
            this.loadProfileLinkTarget.href = `/admin/profiles/${profileId}/${desiredPath}`;
            this.loadProfileLinkTarget.click();
        }
    }
}
