import { Controller } from '@hotwired/stimulus';

export default class PostNavController extends Controller {
  static targets = [
    "prevLink",
    "nextLink",
  ];

  connect() {
    window.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    window.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event) => {
    const key = event.key;

    switch (key) {
      case "ArrowLeft":
        this.prevLinkTarget?.click();
        break;
      case "ArrowRight":
        this.nextLinkTarget?.click();
        break;
    }
  }
}
