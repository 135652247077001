import { Controller } from '@hotwired/stimulus'

/*
 * Passes keydown events from a <code>&lt;label&gt;</code> to its associated <code>&lt;input&gt;</code>.
 * Reference this controller on the <code>&lt;label&gt;</code> itself.
 */
export default class extends Controller {
  initialize() {
    this.labelTarget = this.element;
    this.labelTarget.getAttribute('for');

    if(!this.labelTarget.getAttribute('for')) {
      console.warn("No input found for radio label controller. Make sure the label has a 'for' attribute.");
      return;
    }

    this.inputTarget = document.getElementById(this.labelTarget.getAttribute('for'));
    this.labelTarget.addEventListener('keydown', this.onKeyDown.bind(this));
  }

  onKeyDown(event) {
    if(event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      this.inputTarget.click();
    }
  }
}
