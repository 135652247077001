import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
    connect() {
      tinymce.init({
        selector: 'textarea.tinymce',
        toolbar: 'bold italic underline',
        formats: {
          underline: { inline: 'u', exact: true }
        },
        menubar: false,
        height: 200,
        statusbar: false
      });

    }

    disconnect() {
      let editor = tinymce.get(this.element.id);
      if (editor) {
          editor.remove();
      }
    }
}
