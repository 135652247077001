import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static values = {
    enableTime: Boolean,
    dateFormat: { type: String, default: "Y-m-d" },
    altFormat: String,
    minDate: String,
    maxDate: String,
  };

  connect() {
    this.fp = flatpickr(this.element, {
      enableTime: this.enableTimeValue,
      dateFormat: this.dateFormatValue,
      altInput: true,
      altFormat: this.altFormatValue || "F j, Y",
      minDate: this.minDateValue || undefined,
      maxDate: this.maxDateValue || undefined,
    });
  }

  disconnect() {
    this.fp.destroy();
  }
}
