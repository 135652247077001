import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["delete", "subform"];

  connect() {
    this.deleteTarget.addEventListener("change", () => {
      this.handleDelete();
    });
  }

  handleDelete() {
    if (this.element.hasAttribute("id")) {
      this.subformTarget.style.display = "none";
    } else {
      this.subformTarget.remove();
    }
  }
}
