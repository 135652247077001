import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["box", "form", "arrow"];

  connect() {
    this.menuOpen = false;
  }

  toggle() {
    if (this.menuOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  reset(event) {
    event.preventDefault()

    this.close();

    this.formTarget.reset()
  }

  close() {
    this.boxTarget.classList.remove("slide-forward");
    this.boxTarget.classList.add("slide-backward");
    this.arrowTarget.classList.remove("chevron-right");
    this.menuOpen = false;
  }

  open() {
    this.boxTarget.classList.add("slide-forward");
    this.boxTarget.classList.remove("slide-backward");
    this.arrowTarget.classList.add("chevron-right");
    this.menuOpen = true;
  }
}
