import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['notice'];

  checkLength(event) {
    if (event.target.value.length >= event.target.maxLength) {
      this.noticeTarget.classList.remove('hidden');
    } else {
      if (!this.noticeTarget.classList.contains('hidden')) {
        this.noticeTarget.classList.add('hidden');
      }
    }
  }
}