import { Controller } from '@hotwired/stimulus';
import "@hotwired/turbo";

/**
 * Updates the hidden input "position" values for items within a jQuery Sortable container.
 * The container is expected to contain a list of items, each with a hidden `input[class="position"]` field.
 */
export default class extends Controller {
    static targets = ["container"];

    initialize() {
        document.addEventListener('sortStopped', () => {
            // update the position on each card to reflect the reshuffled order on the current DOM.
            this.containerTarget.querySelectorAll('input.position').forEach((childPosition, index) => {
                childPosition.setAttribute('value', index);
            });
        });
    }
}

