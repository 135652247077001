import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        link: String
    }

    connect() {
        this.modal = document.getElementById('shareModal');
        this.overlay = document.getElementById('pageOverlay');
        this.copiedMessage = document.getElementById('copiedMessage');

        document.addEventListener('click', (event) => this.handleClickOutside(event));

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape' && this.modal.style.display === 'block') { 
                this.hideModal();
            }
        });
    }

    copy() {
        if (!navigator.clipboard) {
            console.warn("Cannot use clipboard API");
            return;
        }

        navigator.clipboard.writeText(this.linkValue).then(() => {
          this.showModal();
          setTimeout(() => {
            this.showCopiedMessage();
          }, 500);
        });
    }

    showCopiedMessage() {
      this.copiedMessage.style.display = 'block';
    }

    showModal() {
        this.modal.style.display = 'block';
        this.overlay.style.display = 'block';
    }

    hideModal() {
        this.modal.style.display = 'none';
        this.overlay.style.display = 'none';
        this.copiedMessage.style.display = 'none';
    }

    handleClickOutside(event) {

      let modalContent = this.modal.querySelector('.share-modal-content');

      if (!modalContent.contains(event.target) && this.modal.style.display === "block") {
          this.hideModal();
      }
    }
}