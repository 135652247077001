import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ["filterGroupName", "loadGroupLink", "selectGroupId"]

    initialize() {
        this.filterGroupNameTarget.value = "";

        this.filterGroupNameTarget.addEventListener("input", this.filterGroups.bind(this));

        this.selectGroupIdTarget.addEventListener("change", this.selectGroup.bind(this));
        this.selectGroupIdTarget.addEventListener("click", this.selectGroup.bind(this));
        if(this.selectGroupIdTarget.value) {
            this.selectGroup();
        }
    }

    filterGroups() {
        let filterName = this.filterGroupNameTarget.value;
        let groupOptions = this.selectGroupIdTarget.options;
        for(let i = 0; i < groupOptions.length; i++) {
            let groupOption = groupOptions[i];
            let groupName = groupOption.innerText;
            if(groupName.toLowerCase().includes(filterName.toLowerCase())) {
                groupOption.style.display = 'block';
            } else {
                groupOption.style.display = 'none';
            }
        }
    }

    selectGroup() {
        let groupId = this.selectGroupIdTarget.value;
        if(groupId) {
            this.loadGroupLinkTarget.href = `/influencers/groups/${groupId}`;
            this.loadGroupLinkTarget.click();
        }
    }
}
