import { Controller } from "@hotwired/stimulus";

/**
 * Dismisses flash messages when the dismiss button is clicked.
 */
export default class extends Controller {

    static targets = ["container", "control"];

    connect() {
        setTimeout(() => { // automatically dismiss flash messages after 5 seconds.
            this.dismiss(new CustomEvent("programmatic"));
        }, 5000);
    }

    dismiss(event) {
        event.preventDefault();
        event.stopPropagation();

        this.containerTarget.classList.add("invisible");
        setTimeout(() => {
            this.containerTarget.remove();
        }, 500); // delay to give CSS transitions time to fade.
    }
}
