import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["error", "urlInput"];

  connect() {
    this.checkValidity();
  }

  validate() {
    this.checkValidity();
  }

  checkValidity() {
    const value = this.urlInputTarget.value.trim();
    const pattern = /^(https?:\/\/|mailto:|sms:|tel:)/;

    if (!value) {
      this.errorTarget.style.display = 'none';
      return;
    }

    if (pattern.test(value)) {
      this.errorTarget.style.display = 'none';
    } else {
      this.errorTarget.style.display = 'block';
    }
  }
}
