import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    closeOnFormSuccess: { type: Boolean, default: true }, // When set to true, the modal will close when the form is successfully submitted with turbo
    closeOnOutsideClick: { type: Boolean, default: true }, // When set to true, the modal will close when the user clicks outside the modal content
  };

  static targets = [
    "content", // The content of the modal, used to determine if click events should close the modal
  ];

  connect() {
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  addEventListeners() {
    window.addEventListener("keyup", this.onKeyup);

    if (this.closeOnOutsideClickValue) {
      window.addEventListener("click", this.onClickOutside);
    }

    if (this.closeOnFormSuccessValue) {
      this.element.addEventListener("turbo:submit-end", this.onSubmitEnd);
    }
  }

  removeEventListeners() {
    window.removeEventListener("keyup", this.onKeyup);

    if (this.closeOnOutsideClickValue) {
      window.removeEventListener("click", this.onClickOutside);
    }

    if (this.closeOnFormSuccessValue) {
      this.element.removeEventListener("turbo:submit-end", this.onSubmitEnd);
    }
  }

  onKeyup = (event) => {
    if (event.key !== "Escape") {
      return;
    }

    this.cancel();
  };

  onClickOutside = (event) => {
    if (!this.hasContentTarget || this.contentTarget.contains(event.target)) {
      return;
    }

    this.cancel();
  };

  onSubmitEnd = (event) => {
    const { success } = event.detail;

    if (!success) {
      return;
    }

    this.complete();
  };

  cancel() {
    this.close(new CustomEvent("modal:cancel"));
  }

  complete() {
    this.close(new CustomEvent("modal:complete"));
  }

  close(event) {
    window.dispatchEvent(event);
    this.element.parentElement.src = "";
    this.element.remove();
  }
}
