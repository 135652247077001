import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  connect() {
    if (localStorage.getItem('visitedBefore') !== 'true') {
      // Define the element and distances
      let element = document.getElementById('linkScroll');
      let maxScrollLeft = element.scrollWidth - element.clientWidth;
      let scrollLimit = maxScrollLeft / 10; // Smaller distance for a "jiggle"
      let scrollAmount = 0;
      let scrollSpeed = 2; // Increase for a faster jiggle
      let cycleCount = 0;

      // Define the scroll function
      function scrollLinks() {
        // Scroll to the right
        let scrollRight = setInterval(function() {
          if (scrollAmount < scrollLimit) {
            element.scrollLeft += scrollSpeed;
            scrollAmount += scrollSpeed;
          } else {
            clearInterval(scrollRight);
            // Scroll to the left
            let scrollLeft = setInterval(function() {
              if (scrollAmount > 0) {
                element.scrollLeft -= scrollSpeed;
                scrollAmount -= scrollSpeed;
              } else {
                clearInterval(scrollLeft);
                cycleCount++;
                if (cycleCount < 2) { // two jiggles
                  scrollLinks();
                }
              }
            }, 10); 
          }
        }, 10); 
      }

      // Call the scroll function
      scrollLinks();

      localStorage.setItem('visitedBefore', 'true');
    }
  }
}


