import { Controller } from '@hotwired/stimulus';
import "@hotwired/turbo";

export default class extends Controller {
  static targets = ["form"];
  hasUnsavedChanges = false;

  initialize() {
    this.setupEventListeners();
  }
  
  connect() {
    // Bind the methods and store the references
    this.boundHandleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.boundHandleTurboBeforeVisit = this.handleTurboBeforeVisit.bind(this);

    // Add event listeners using the bound references
    window.addEventListener('beforeunload', this.boundHandleBeforeUnload);
    document.addEventListener('turbo:before-visit', this.boundHandleTurboBeforeVisit);
  }


  setupEventListeners() {
    // Listen for changes in input, select, and textarea elements
    this.element.addEventListener('change', event => {
      if (event.target.matches('input, select, textarea')) {
        this.handleSubmitOnChange(event);
      }
    });

    // Listen for keyup events, specifically in text inputs and textareas
    this.element.addEventListener('keyup', event => {
      if (event.target.matches('input, select, textarea')) {
        this.handleSubmitOnChange(event);
      }
    });

    document.addEventListener('sortStopped', (event) => {
      setTimeout(() => {
        this.handleSubmitOnChange(event);
      }, 500); 
    });

    this.formTarget.querySelector('#contact-save-button').addEventListener('click', (event) => {
      this.hasUnsavedChanges = false;
    });
    this.formTarget.querySelector('#contact-cancel-button').addEventListener('click', (event) => {
      this.hasUnsavedChanges = false;
    });  


    // Handle "Back" button click 
    const backButton = this.element.querySelector('#contact-back-button');
    if (backButton) {
      backButton.addEventListener('click', (event) => {
        if (this.hasUnsavedChanges) {
          const confirmationMessage = "Unsaved changes will be lost if you leave this page. Do you wish to continue?";
          if (!confirm(confirmationMessage)) {
            // User clicked "Cancel", stay on the page
            event.preventDefault();
          } else {
            // User clicked "OK", reset hasUnsavedChanges and allow navigation
            this.hasUnsavedChanges = false;
            location.reload();
          }
        }
      });
    }
  }
  
  handleSubmitOnChange(event) {
    event.preventDefault();
  
    const formSubmitUrl = this.element.getAttribute('data-preview-url');
  
    this.element.action = formSubmitUrl;
    this.element.method = 'post';
  
    // Remove or modify the hidden _method input
    const methodInput = this.element.querySelector('input[name="_method"]');
    if (methodInput) {
      methodInput.value = 'post'; 
    }
    
    this.element.setAttribute('target', 'galleryPreviewIframe');
    this.element.submit();

    // Update the hidden iframe
    this.formTarget.setAttribute('target', 'web-iframe')
    this.formTarget.submit();

    this.revertForm();
    this.hasUnsavedChanges = true;

  }

  revertForm() {
    const originalFormUrl = this.element.getAttribute('data-form-submit-url');
    this.element.action = originalFormUrl;

    this.element.method = 'patch';

    const methodInput = this.element.querySelector('input[name="_method"]');
    if (methodInput) {
      methodInput.value = 'patch'; 
    }

    this.element.removeAttribute('target');
  }

  handleBeforeUnload(event) {
    if (this.hasUnsavedChanges) {
      event.preventDefault();
      event.returnValue = "Unsaved changes will be lost if you leave this page. Do you wish to continue?";

      setTimeout(() => {
        if (!document.hidden) { // Check if the document is still visible
          this.hasUnsavedChanges = false; // User stayed on the page, reset flag
        }
      }, 0);
    }
  }

  handleTurboBeforeVisit(event) {
    if (this.hasUnsavedChanges) {
      const confirmationMessage = "Unsaved changes will be lost if you leave this page. Do you wish to continue?";
      if (!confirm(confirmationMessage)) {
        // User clicked "Cancel", prevent navigation
        event.preventDefault();
      } else {
        // User clicked "OK", allow navigation
        this.hasUnsavedChanges = false;
      }
    }
  }

  disconnect() {
    // Remove the event listeners using the stored references
    window.removeEventListener('beforeunload', this.boundHandleBeforeUnload);
    document.removeEventListener('turbo:before-visit', this.boundHandleTurboBeforeVisit);
  }
}