import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import 'jquery';
import $ from 'jquery';
import Packery from 'packery';
import jQueryBridget from 'jquery-bridget';

// make Packery a jQuery plugin
jQueryBridget( 'packery', Packery, $ );

let page = 1;
const path = window.location.pathname;

export default class extends Controller {
  static targets = ['posts', 'pagination'];
  static values = { page: Number, totalPages: Number, breakBetweenAds: Number }
  loadedGoogleAds = 0;
  loadedSovrnAds = 0;

  initialize() {
    this.loadAd = this.loadAd.bind(this);
    this.unloadAd = this.unloadAd.bind(this);

    this.adTemplate = document.querySelector('.new-ad')
    this.sovrnAdTemplate = document.querySelector('.new-sovrn-ad');


    if(!this.pageValue || this.pageValue > this.totalPages) {
      this.pageValue = 1;
    }

    let options = {
      rootMargin: window.innerHeight + 'px',
    };


    this.intersectionObserver = new IntersectionObserver(
      (posts) => this.processIntersectionposts(posts),
      options
    );

    this.adsObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadAd(entry.target);
          } else {
            this.unloadAd(entry.target);
          }
        });
      },
      options
    );
  }

  connect() {

    window.addEventListener('scroll', this.handleScroll.bind(this));
    this.intersectionObserver.observe(this.paginationTarget);
    this._removeExtraSectionTags();
    this._removeExtraAds();
    this._hideAdIfNoPrevSibling();

    this.updatePackeryGrid = this.updatePackeryGrid.bind(this);
    document.addEventListener('viewTypeChanged', this.updatePackeryGrid);

    this.packeryGrid = new Packery('.gallery-grid', {
      itemSelector: '.gallery-item',
      columnWidth: '.grid-sizer',
      rowHeight: 1,

    });
    this.handleScroll();

    if(window.innerHeight > 2000) {
      // load more posts immediately if the page is rather tall.
      this.loadMore();
    }
  }

  handleScroll() {
    this.element.querySelectorAll('.ad-item').forEach(adItem => {
      if (this.isElementInViewport(adItem)) {
        this.loadAd(adItem);
      } else {
        this.unloadAd(adItem);
      }
    });

  }

  loadAd(adContainer) {
    // Stop loading google ads if we've already loaded 3
    if (this.loadedGoogleAds >= 3) {
      //stop loading sovrn ads if we've already loaded 5
      if (this.loadedSovrnAds >= 5) {
        return
      }
      //dont keep loading ads if an ad is already loaded
      if (adContainer.dataset.loaded === 'true') {
        return;
      }
      //load sovrn ads once google has reached 3 max ads
      let adContent = document.importNode(this.sovrnAdTemplate.content, true);
      adContainer.appendChild(adContent);
      // Increase the loadedSovrnAds count
      this.loadedSovrnAds++;
      // Mark the ad as loaded
      adContainer.dataset.loaded = 'true';
      adContainer.dataset.adType = 'sovrn';
    }
    //dont keep loading ads if an ad is already loaded
    if (adContainer.dataset.loaded === 'true') {
      return;
    }

    // Clone the adTemplate content
    let adContent = document.importNode(this.adTemplate.content, true);
    adContainer.appendChild(adContent);

    // Increase the loadedGoogleAds count
    this.loadedGoogleAds++;

    // Mark the ad as loaded
    adContainer.dataset.loaded = 'true';
    adContainer.dataset.adType = 'google';
  }

  unloadAd(adContainer) {
    // Ad is not in the viewport, remove the ad content
    const content = adContainer.querySelector('.ad');

    if (content) {
      content.remove();

      if (adContainer.dataset.adType === 'google') {
        this.loadedGoogleAds--;
      }
      if (adContainer.dataset.adType === 'sovrn') {
        this.loadedSovrnAds--;
      }
    }

    adContainer.dataset.loaded = 'false';
  }


  //as soon as any part of the ad container is in view, the ad is added
  isElementInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.bottom > 0 &&
      rect.right > 0
    );
  }

  updatePackeryGrid(event) {
    const viewType = event.detail.viewType;

    if(viewType === 'list') {
      this.element.classList.add('list')
      sessionStorage.setItem('galleryViewType', 'list');
    } else if(viewType === 'grid') {
      this.element.classList.remove('list')
      sessionStorage.setItem('galleryViewType', 'grid');
    }

    this.packeryGrid.layout()
  }

  processIntersectionposts(posts) {
    posts.forEach((post) => {
      if (post.isIntersecting) {
        this.loadMore();
      }
    });
    this._removeExtraSectionTags();
    this._removeExtraAds();
    this._hideAdIfNoPrevSibling();
  }

  loadMore() {
    const currentPage = this.pageValue;
    const nextPage = currentPage + 1;
    if(nextPage > this.totalPages) {
      console.log("No more pages to load.");
      return;
    }

    this.pageValue = nextPage;

    // Build URL with filter parameters
    let url = new URL(`${window.location.pathname}?${window.location.search}`, window.location.origin);
    url.searchParams.set('page', nextPage);

    // Assuming that filters are stored in window.location.search
    let searchParams = new URLSearchParams(window.location.search);
    for(let pair of searchParams.entries()) {
      // Include all filter parameters in the request
      url.searchParams.set(pair[0], pair[1]);
    }

    this.paginationTarget.innerHTML = '<div class="spinner"></div>';

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        let grid = jQuery('.gallery-grid')
        let tempDiv = document.createElement('div');
        this.totalPages = data.total_pages;
        tempDiv.innerHTML = data.posts;
        let newItems = Array.prototype.filter.call(tempDiv.childNodes, function(node) {
          return node.nodeType === Node.ELEMENT_NODE;
        });

        //Add Ads to every 5th post
        newItems.forEach((newItem, index) => {
          if ((index + 1) % (this.breakBetweenAdsValue) === 0) {
            let adContainer = document.createElement('div');
            adContainer.classList.add('gallery-item', 'ad-item');

            // Clone the adTemplate
            let adTemplate = this.adTemplate.cloneNode(true);

            adContainer.appendChild(adTemplate);

            newItems.splice(index + 1, 0, adContainer);
          }
        });

        // Append newItems to the grid
        grid.append(newItems);
        this.packeryGrid.appended(newItems);
        this.packeryGrid.layout();
        if (data.pagination) {
          this.paginationTarget.innerHTML = data.pagination;
        } else {
          this.paginationTarget.innerHTML = '';
        }
        //observe new items for intersection changes
        newItems.forEach(newItem => {
          if(newItem.classList.contains('ad-item')) {
            this.adsObserver.observe(newItem);
          }
        });

      },
    });
  }

  removeSpinner() {
    const spinnerElement = this.paginationTarget.querySelector('.spinner');
    if (spinnerElement) {
      spinnerElement.remove();
    }
  }

  _removeExtraSectionTags() {
    const sectionTags = document.querySelectorAll('section');
    sectionTags.forEach((sectionTag) => {
      if (sectionTag.childNodes.length <= 1) sectionTag.remove();
    });
  }

  _removeExtraAds() {
    const gridItemsHeight2 = document.querySelectorAll(
      '.grid-item--height2.mt-2'
    );
    gridItemsHeight2.forEach((gridItem) => {
      const adNodes = gridItem.querySelectorAll('.ad-card');
      adNodes.length &&
        adNodes.forEach((adNode, index) => {
          index !== 0 && adNode.remove();
        });
    });
  }

  _hideAdIfNoPrevSibling() {
    const adNodes = document.querySelectorAll('.ad-card');
    adNodes.forEach((adNode) => {
      const adParentNode = adNode.parentNode;
      const prevSibling = adParentNode.previousElementSibling;
      prevSibling.childNodes.length < 10
        ? adNode.classList.add('d-none')
        : adNode.classList.remove('d-none');
    });
  }
}
