import { Controller } from '@hotwired/stimulus';
import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';

export default class extends Controller {
    static targets = ['image', 'icon', 'iconPicker', 'imagePurge', 'preview']

    connect() {
        document.addEventListener('mousedown', (event) => {
            if (!this.element.contains(event.target)) {
                this.hideIcons();
            }
        });

        // on preview click, show the `.choose-image-option-menu`.
        // this is needed for mobile users to access the option menu.
        this.previewTarget.addEventListener('click', (event) => {
            this.toggleOptionMenu();
        });

        // hide the `.choose-image-option-menu` when the user clicks outside of it.
        document.addEventListener('mousedown', (event) => {
            if (!this.previewTarget.parentElement.contains(event.target)) {
                this.hideOptionMenu();
            }
        });

    }

    hideIcons() {
        this.iconPickerTarget.classList.add('hidden');
        this.iconPickerTarget.innerHTML = '';
    }

    purgeImage() {
        this.imageTarget.value = "";
        this.imagePurgeTarget.value = "1";
    }

    selectFile() {
        this.hideOptionMenu();
        this.imageTarget.click();
    }

    hideOptionMenu() {
        this.previewTarget.parentElement.querySelector('.choose-image-option-menu').classList.remove('show');
    }

    toggleOptionMenu() {
        this.previewTarget.parentElement.querySelector('.choose-image-option-menu').classList.toggle('show');
    }

    updateImagePreview() {
      this.previewTarget.innerHTML = '';

      if (this.imageTarget.files && this.imageTarget.files[0]) {
        const reader = new FileReader();
        reader.onload = (event) => {
            const image = document.createElement('img');
            image.alt = '';
            image.src = event.target.result;
            this.previewTarget.appendChild(image);
        };
        reader.readAsDataURL(this.imageTarget.files[0]);
      } else if (this.iconTarget.value) {
        // Check if the value looks like an SVG file path (custom emoji)
        if (this.iconTarget.value.endsWith('.svg')) {
          const img = document.createElement('img');
          img.src = this.iconTarget.value;
          img.style.width = '20px';
          img.style.height = '20px';
          this.previewTarget.appendChild(img);
        } else {
          // Handle standard emoji icons
          const icon = document.createElement('span');
          icon.textContent = this.iconTarget.value;
          this.previewTarget.appendChild(icon);
        }
      }
      document.dispatchEvent(new CustomEvent('updateGalleryPreview'));
    }

    viewIcons() {
   
      this.hideOptionMenu();
      this.iconPickerTarget.classList.remove('hidden');

      const customSocialMediaIcons = [
        {
          id: 'social',
          name: 'Social Media',
          emojis: [
            {
              id: 'facebook',
              name: 'Facebook',
              keywords: ['facebook', 'social', 'media'],
              custom: true,
              skins: [{ src: '/images/Facebook.svg' }], 
            },
            {
              id: 'instagram',
              name: 'Instagram',
              keywords: ['instagram', 'social', 'media'],
              custom: true,
              skins: [{ src: '/images/Instagram.svg' }], 
            },
            {
              id: 'youtube',
              name: 'YouTube',
              keywords: ['youtube', 'social', 'media'],
              custom: true,
              skins: [{ src: '/images/Youtube.svg' }], 
            },
            {
              id: 'twitter',
              name: 'Twitter',
              keywords: ['twitter', 'social', 'media'],
              custom: true,
              skins: [{ src: '/images/Twitter.svg' }],
            },
            {
              id: 'tiktok',
              name: 'TikTok',
              keywords: ['tiktok', 'social', 'media'],
              custom: true,
              skins: [{ src: '/images/TikTok.svg' }], 
             },
          ],
        },
      ];
    
      // Initialize Emoji-Mart Picker
      const picker = new Picker({
        data: data,
        custom: customSocialMediaIcons,
        onEmojiSelect: (emoji) => this.handleEmojiSelect(emoji)
      });

      // Append the picker to the DOM
      this.iconPickerTarget.appendChild(picker);
    }

    handleEmojiSelect(emoji) {

      if (emoji.id && emoji.src) { // Custom emoji with an SVG image
        this.iconTarget.value = emoji.src;
      } else {
        // Standard emoji
        this.iconTarget.value = emoji.native;
      }
      this.hideIcons();
      this.purgeImage();
      this.updateImagePreview();
    
      document.dispatchEvent(new CustomEvent('updateGalleryPreview'));
    }
}
