import { Controller } from '@hotwired/stimulus'
import $ from 'jquery';

/*
 * Creates a Selectize element from a select box, intended for use with tags.
 */
export default class extends Controller {
    initialize() {
        const elementId = this.element.getAttribute('id');
        if (!elementId) {
            console.log("No ID found for selectize element", this.element);
        }

        $("#" + elementId).selectize({
            plugins: ["restore_on_backspace", "clear_button", "remove_button"],
            create: true,
            persist: false,
            maxItems: null,
            sortField: 'text'
        });
    }
}
