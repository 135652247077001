import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = { url: String, sync: Boolean, source: String }

    connect() {
        if (this.syncValue && this.urlValue !== null && this.urlValue !== '') {
            console.log(`Initiate sync for ${this.sourceValue}`)
            window.open(this.urlValue, '_blank')
        }
    }
}