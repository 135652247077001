import { Controller } from '@hotwired/stimulus'

/*
 * Adds the <code>hidden</code> class to the selector-targeted element.
 */
export default class extends Controller {
    static values = {
        selector: String
    };

    connect() {
        this.clickableTarget = this.element;
        this.selectedTarget = document.querySelector(this.selectorValue);
        this.clickableTarget.addEventListener('click', this.hide.bind(this));
    }

    hide(event) {
        // don't let click events on overlaid children bubble up
        if (event.target !== this.clickableTarget) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        this.selectedTarget.classList.add('!hidden');

        return false;
    }
}
