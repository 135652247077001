import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.currentModal = null;
  }

  toggle(event) {
    const button = event.currentTarget;
    const modal = button.nextElementSibling; // Assumes modal is the immediate next sibling

    event.stopPropagation();

    if (this.currentModal === modal && !modal.classList.contains('hidden')) {
      this.close();
    } else {
      this.openModal(modal);
    }
  }

  openModal(modal) {
    if (this.currentModal && this.currentModal !== modal) {
      this.close();
    }

    this.currentModal = modal;
    modal.classList.remove('hidden');

    // Add event listener for clicks outside the modal
    setTimeout(() => document.addEventListener('click', this.handleClickOutside), 0);
  }

  close() {
    if (this.currentModal) {
      this.currentModal.classList.add('hidden');
      this.currentModal = null;
      document.removeEventListener('click', this.handleClickOutside);
    }
  }

  handleClickOutside(event) {
    if (this.currentModal && !this.currentModal.contains(event.target)) {
      this.close();
    }
  }
}