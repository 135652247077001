import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["filterBrandName", "loadBrandLink", "selectBrandId"]

    initialize() {
        this.filterBrandNameTarget.value = "";

        this.selectBrandIdTarget.addEventListener("change", this.selectBrand.bind(this));
        this.selectBrandIdTarget.addEventListener("click", this.selectBrand.bind(this));
        if(this.selectBrandIdTarget.value) {
            this.selectBrand();
        }
    }

    filterBrands() {
      let filterName = this.filterBrandNameTarget.value;
      let brandOptions = this.selectBrandIdTarget.options;

      for (let i = 0; i < brandOptions.length; i++) {
        let brandOption = brandOptions[i];
        let brandName = brandOption.innerText;
        if (brandName.toLowerCase().includes(filterName.toLowerCase())) {
          brandOption.style.display = 'block';
        } else {
          brandOption.style.display = 'none';
        }
      }
    }

    selectBrand() {
      const brandId = this.selectBrandIdTarget.value;

      if (brandId) {
        this.loadBrandLinkTarget.href = `/admin/brands/${brandId}/edit`;
        this.loadBrandLinkTarget.click();
      }
    }
}