import { Controller } from "@hotwired/stimulus";

/**
 * Sets a hidden field's value based on clicking a choice.
 */
export default class extends Controller {
    static targets = [
        "choices", // a parent element that contains all tab controls and panels.
        "input" // the field whose value will change based on the user's selection.
    ];
    initialize() {
        let choiceElements = this.choicesTarget.querySelectorAll('[data-option-value]');

        // add event listeners for choice elements.
        choiceElements.forEach(choice => {
            choice.addEventListener('click', this.choiceSelected.bind(this));
            choice.addEventListener('keydown', this.choiceSelected.bind(this));
        });
    }

    choiceSelected(event) {
        // listen for key and click events
        if (event.type === "keydown" && event.key !== "Enter" && event.key !== " ") {
            return true; // allow event bubbling.
        }

        let choice = event.currentTarget;
        this.inputTarget.value = choice.dataset.optionValue;

        return true; // permit other controllers to do things with this event.
    }
}
