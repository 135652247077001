import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["slider", "caretPrevious", "caretNext"]

  connect() {
    // Retrieve the stored slide index from the session storage
    const storedSlideIndex = sessionStorage.getItem('carouselCurrentSlide');
    if (storedSlideIndex) {
      this.currentSlideIndex = parseInt(storedSlideIndex, 10);
    } else {
      this.currentSlideIndex = 0;
    }

    this.showSlide();
  }

  next() {
    if (this.currentSlideIndex < this.sliderTargets.length - 1) {
      this.currentSlideIndex += 1;
      // Save the current slide index to the session storage
      sessionStorage.setItem('carouselCurrentSlide', this.currentSlideIndex);
    }
    this.showSlide();
  }

  previous() {
    if (this.currentSlideIndex > 0) {
      this.currentSlideIndex -= 1;
      // Save the current slide index to the session storage
      sessionStorage.setItem('carouselCurrentSlide', this.currentSlideIndex);
    }
    this.showSlide();
  }

  showSlide() {
    // Scroll to the current slide
    if(this.sliderTargets && this.sliderTargets[this.currentSlideIndex]) {
      this.sliderTargets[this.currentSlideIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }

    // Apply or remove the deactivated class on the caretPrevious
    if (this.currentSlideIndex === 0) {
      this.caretPreviousTarget.classList.add("deactivated");
    } else {
      this.caretPreviousTarget.classList.remove("deactivated");
    }

    // Apply or remove the deactivated class on the caretNext
    if (this.currentSlideIndex === this.sliderTargets.length - 1) {
      this.caretNextTarget.classList.add("deactivated");
    } else {
      this.caretNextTarget.classList.remove("deactivated");
    }
  }
}
