import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["slider", "seeStatsText", "nextCaret", "bannerContainer", "nextButton", "previousButton"];

  // Define touchStart and touchEnd coordinates
  xDown = null;
  yDown = null;

  connect() {
    this.slideIndex = 0;
    // Add event listeners for touch events
    this.element.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
    this.element.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
    this.element.addEventListener('touchend', this.handleTouchEnd.bind(this), false);

    this.debouncedCheckActiveSlide = this.debounce(this.checkActiveSlideAndUpdateButtons.bind(this), 10);
    this.bannerContainerTarget.addEventListener('scroll', this.debouncedCheckActiveSlide, false);

    this.updateButtons();
  }

  // Get the coordinates of touch start
  handleTouchStart(event) {
    this.xDown = event.touches[0].clientX;
    this.yDown = event.touches[0].clientY;

    // Check if touch start is inside bannerContainerTarget
    this.isSwipeInsideBanner = this.bannerContainerTarget.contains(event.target);
  }

  // Detect direction and make the slide
  handleTouchMove(event) {
    if (!this.xDown || !this.yDown) {
      return;
    }

    let xUp = event.touches[0].clientX;
    let yUp = event.touches[0].clientY;

    let xDiff = this.xDown - xUp;
    let yDiff = this.yDown - yUp;

    // Detect swipe direction (horizontal)
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        /* left swipe */
        this.swipeDirection = 1;
      } else {
        /* right swipe */
        this.swipeDirection = -1;
      }
    }

    // Reset values
    this.xDown = null;
    this.yDown = null;
  }

  handleTouchEnd() {
    if (this.swipeDirection === -1) {
      this.swipe(-1);

    } else if (this.swipeDirection === 1) {
      this.swipe(1);
    }
    this.swipeDirection = null; // Reset the direction after handling it
  }

  swipe(direction) {

    if (!this.isSwipeInsideBanner) {

      // Get the current scroll position
      let currentScrollPosition = this.bannerContainerTarget.scrollLeft;

      // Calculate the width of a single slide
      let slideWidth = this.bannerContainerTarget.offsetWidth;

      // Use `-` for swipeRight and `+` for swipeLeft
      let newScrollPosition = currentScrollPosition + (slideWidth * direction);

      this.bannerContainerTarget.scrollTo({
          left: newScrollPosition,
          behavior: "smooth"
      });
    }

    // Adjust the slideIndex based on direction
    if (direction === -1 && this.slideIndex > 0) {
        this.slideIndex--;
    } else if (direction === 1 && this.slideIndex < this.sliderTargets.length - 1) {
        this.slideIndex++;
    }

    this.updateButtons();
  }

  // Next slide function
  nextSlide() {
    if (this.slideIndex < this.sliderTargets.length - 1) {
      this.slideIndex += 1;
    }

    this.showSlide(this.slideIndex);
  }

  // Previous slide function
  prevSlide() {
    if (this.slideIndex > 0) {
      this.slideIndex -= 1;
    }

    this.showSlide(this.slideIndex);
  }

  showSlide(slideIndex) {
      // Scroll to the current slide
      let slideElement = this.sliderTargets[slideIndex];

      //slideElement.scrollIntoView(true);
      slideElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

      this.updateButtons();
  }

  updateButtons() {
    // Hide the previous button if on the first slide
    const prevButton = this.element.querySelector('.previous_button');
    if (this.slideIndex === 0) {
      prevButton.style.visibility = 'hidden';
    } else {
      prevButton.style.visibility = 'visible';
    }

    // Hide the SEE STATS text if not on the first slide
    if (this.slideIndex !== 0) {
      this.seeStatsTextTarget.style.visibility = 'hidden';
    } else {
      this.seeStatsTextTarget.style.visibility = 'visible';
    }

    // Hide the next caret if on the last slide
    if (this.slideIndex === this.sliderTargets.length - 1) {
      this.nextCaretTarget.style.visibility = 'hidden';
    } else {
      this.nextCaretTarget.style.visibility = 'visible';
    }

    //handle animation for profile image
    let profileImage = this.element.querySelector('.profile_image_container');
    let xOffset = window.innerWidth >= 1130 ? -300 : -100;

    if (this.slideIndex === 0) {
      profileImage.style.transform = 'translateX(0)'; // Reset to original position
      profileImage.style.opacity = '1'; // Ensure it's visible
    } else if (this.slideIndex === 1) {
      profileImage.style.transform = `translateX(${xOffset}px)`;
      profileImage.style.opacity = '1'; // Ensure it's visible
    } else if (this.slideIndex === 2) {
      profileImage.style.opacity = '0'; // Hide the profile image
    }
  }

  checkActiveSlideAndUpdateButtons() {
    // Get the current scroll position
    let currentScrollPosition = this.bannerContainerTarget.scrollLeft;

    // Calculate the width of a single slide
    let slideWidth = this.bannerContainerTarget.offsetWidth;

    // Determine the current slide index based on the scroll position and slide width
    this.slideIndex = Math.round(currentScrollPosition / slideWidth);

    this.updateButtons();
  }

  debounce(func, wait) {
    let timeout;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(context, args);
        }, wait);
    };
  }
}
