import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["slider", "indicator"];

  // Define touchStart and touchEnd coordinates
  xDown = null;
  yDown = null;

  connect() {
    this.slideIndex = 0;

    // Add event listeners for touch events
    this.element.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
    this.element.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
  }

  // Call this function when the indicators are clicked
  slideTo(event) {
    this.slideIndex = parseInt(event.currentTarget.dataset.originalCarouselSlideTo, 10);
    this.showSlide();
  }

  // Get the coordinates of touch start
  handleTouchStart(event) {
    this.xDown = event.touches[0].clientX;
    this.yDown = event.touches[0].clientY;
  }

  // Detect direction and make the slide
  handleTouchMove(event) {
    if (!this.xDown || !this.yDown) {
      return;
    }

    let xUp = event.touches[0].clientX;
    let yUp = event.touches[0].clientY;

    let xDiff = this.xDown - xUp;
    let yDiff = this.yDown - yUp;

    // Detect swipe direction (horizontal)
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        /* left swipe */
        this.nextSlide();
      } else {
        /* right swipe */
        this.prevSlide();
      }
    }

    // Reset values
    this.xDown = null;
    this.yDown = null;
  }

  // Next slide function
  nextSlide() {
    if (this.slideIndex < this.sliderTargets.length - 1) {
      this.slideIndex += 1;
    }
    this.showSlide();
  }

  // Previous slide function
  prevSlide() {
    if (this.slideIndex > 0) {
      this.slideIndex -= 1;
    }
    this.showSlide();
  }

  // Show the current slide and set the corresponding indicator as active
  showSlide() {
    // Remove active class from all indicators
    this.indicatorTargets.forEach((el) => {
      el.classList.remove("slider_active");
    });

    // Scroll to the current slide
    const slideElement = this.sliderTargets[this.slideIndex];
    slideElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

    // Set current indicator as active
    this.indicatorTargets[this.slideIndex].classList.add("slider_active");
  }
}
