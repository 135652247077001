import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["container", "toggleButton", "content", "shadow"];

  connect() {
    const storedContainerState = sessionStorage.getItem('containerState');
    if (storedContainerState === 'expanded') {
      this.isExpanded = true
      this.contentTarget.style.maxHeight = '500px';
    } else {
      this.isExpanded = false
      this.contentTarget.style.maxHeight = '0';
    }

    this.startY = 0;
    this.currentY = 0;
    this.dragging = false;

    // Bind this to class methods
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);


    // Add mouse event listeners
    this.contentTarget.addEventListener('transitionend', this.onTransitionEnd);
    this.toggleButtonTarget.addEventListener('mousedown', this.onMouseDown);

  }

  resetPosts() {
    this.resetTarget.classList.add('hidden')
    sessionStorage.removeItem('galleryInputFilter');
    sessionStorage.setItem('gallerySearchReset', 'false')
    sessionStorage.removeItem('selectedSources')

    let profileUrl = window.location.pathname;
    window.location.href = `${profileUrl}`
  }

  toggle() {
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) {
      this.toggleButtonTarget.setAttribute('aria-expanded', 'true');
      this.contentTarget.style.maxHeight = this.contentTarget.scrollHeight + 'px';

      sessionStorage.setItem('containerState', 'expanded');
    } else {
      this.toggleButtonTarget.setAttribute('aria-expanded', 'false');
      this.contentTarget.style.maxHeight = '0';

      this.shadowTarget.classList.remove("expandable_shadow");

      sessionStorage.setItem('containerState', 'collapsed');
    }
  }

  onMouseDown(event) {
    this.startY = event.pageY;
    this.dragging = true;

    // Add mouse move and mouse up listeners
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  onMouseMove(event) {
    if (!this.dragging) return;
    this.currentY = event.pageY;
    let diff = this.currentY - this.startY;

    if (diff > 0) {
      this.isExpanded = true;
      this.contentTarget.style.maxHeight = this.contentTarget.scrollHeight + 'px';

    } else if (diff < 0) {
      this.isExpanded = false;
      this.contentTarget.style.maxHeight = '0';
      this.shadowTarget.classList.remove("expandable_shadow");
    }
  }

  onMouseUp() {
    this.dragging = false;

    // Remove mouse move and mouse up listeners
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  disconnect() {
    // Clean up event listeners
    this.toggleButtonTarget.removeEventListener('mousedown', this.onMouseDown);
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.contentTarget.removeEventListener('transitionend', this.onTransitionEnd);
  }
}
