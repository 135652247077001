import { Controller } from "@hotwired/stimulus";

/**
 * Toggles fullscreen display on an individual post page.
 */
export default class extends Controller {

      static values = {
        link: String
    }

    static targets = ["container", "shareButton"];

    toggle(event) {
        event.preventDefault();
        event.stopPropagation();
        this.containerTarget.classList.toggle("fullscreen");
    }

    copy() {
      if (!navigator.clipboard) {
        console.warn("Cannot use clipboard API")
        return
      }

      navigator.clipboard.writeText(this.linkValue).then(() => {
        this.shareButtonTarget.innerHTML = '<div class="tooltip">Link Copied!</div>';
      })

      setTimeout(() => {
      this.shareButtonTarget.innerHTML = '<i class="fa-fw fa-solid fa-share-nodes" title="Copy Post Link"></i>';
      }, 2000);

  }
}
