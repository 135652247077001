import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["slider", "output", "greaterThan", "lessThan"];

  connect() {
    this.ensureOneChecked();
    this.updateOutput();
  }

  updateOutput() {
    let comparisonText = '';
    if (this.greaterThanTarget.checked) {
      comparisonText = ' followers and more';
    } else if (this.lessThanTarget.checked) {
      comparisonText = ' followers and less';
    }
    this.outputTarget.innerHTML = this.numberWithCommas(this.sliderTarget.value) + comparisonText;
  }

  numberWithCommas(x) {
    return parseFloat(x).toLocaleString();
  }

  toggleCheckbox(event) {
    if (event.target === this.greaterThanTarget && this.greaterThanTarget.checked) {
      this.lessThanTarget.checked = false;
    } else if (event.target === this.lessThanTarget && this.lessThanTarget.checked) {
      this.greaterThanTarget.checked = false;
    } else {
      event.target.checked = true; // Re-check if trying to uncheck the only checked box
    }
    this.updateOutput();
  }

  ensureOneChecked() {
    if (!this.greaterThanTarget.checked && !this.lessThanTarget.checked) {
      this.greaterThanTarget.checked = true; // Default to 'Greater Than' if none is checked
    }
  }
}