import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    this.postTextTarget = this.element.querySelector('.post_text');
    this.originalText = this.postTextTarget.innerText;

    this.truncateText();

    this.originalOnResize = this.onResize;
    this.onResize = function(...args) {
      setTimeout(() => this.originalOnResize(...args), 100);
    }.bind(this);

    window.addEventListener("resize", this.onResize);
  }

  truncateText = () => {
    const ellipsis = '...';
    let truncated_content = this.originalText;

    this.postTextTarget.innerText = truncated_content;
    while (this.postTextTarget.scrollHeight > this.postTextTarget.offsetHeight) {
      truncated_content = truncated_content.slice(0, -1);
      this.postTextTarget.innerText = truncated_content + ellipsis;
    }
  }

  onResize = () => {
    this.postTextTarget.innerText = this.originalText;
    this.truncateText();
  }
}
