import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "preview"];

  static values = {
    action: String,
    method: String
  };

  connect() {
    this.originalFormActionValue = this.formTarget.getAttribute('action');
    this.originalFormMethodValue = this.getInputMethod();
    this.originalFormTargetValue = this.formTarget.getAttribute('target');

    console.log("action", this.actionValue);
    console.log("method", this.methodValue);

    this.setupEventListeners();

    // trigger the preview submission immediately to populate the preview frame with the form's current settings.
    this.handleSubmitOnChange(new CustomEvent("onchange"))
  }

  disconnect() {
    if(this.windowSelectizeListener) {
      window.removeEventListener('selectize:onchange', this.windowSelectizeListener);
    }
  }

  setupEventListeners() {
    this.formTarget.querySelectorAll('input, select, textarea').forEach(input => {
      input.addEventListener('change', this.handleSubmitOnChange.bind(this));
    });

    this.windowSelectizeListener = this.handleSubmitOnChange.bind(this);
    window.addEventListener('selectize:onchange', this.windowSelectizeListener);
  }

  /**
   * Triggers a preview form submission to update the preview iframe with the latest form data.
   * @param event
   */
  handleSubmitOnChange(event) {
    event.preventDefault();

    this.submitPreview(this.actionValue, this.methodValue, this.previewTarget.getAttribute('name'));
  }

  /**
   * Manually triggers a preview form submission to a secondary preview form defined in the event target element's attributes.
   * @param event The event triggering the preview action (e.g., a button click). The event target should have the desired form post attributes.
   */
  previewFromAttributes(event) {
    event.preventDefault();
    console.log("target", event.target);

    const eventAction = event.target.getAttribute('data-form-preview-action');
    const eventMethod = event.target.getAttribute('data-form-preview-method');
    const eventTarget = event.target.getAttribute('data-form-preview-target');

    this.submitPreview(eventAction, eventMethod, eventTarget);
  }

  submitPreview(action, method, target) {
    // point the form to the preview frame.
    this.formTarget.setAttribute('action', action);
    this.setInputMethod(method);
    this.formTarget.setAttribute('target', target);

    // send form data to the preview endpoint.
    this.formTarget.submit();

    // point the form back to its original destination.
    this.formTarget.setAttribute('action', this.originalFormActionValue);
    this.setInputMethod(this.originalFormMethodValue);
    this.formTarget.setAttribute('target', this.originalFormTargetValue);
  }

  getInputMethod() {
    let inputMethod = this.formTarget.querySelector('input[name="_method"]');
    return inputMethod != null ? inputMethod.getAttribute('value') : this.formTarget.getAttribute('method')
  }

  setInputMethod(method) {
    let inputMethod = this.formTarget.querySelector('input[name="_method"]');
    if(inputMethod != null) {
      this.formTarget.querySelector('input[name="_method"]').setAttribute('value', method);
    }
    else {
      this.formTarget.setAttribute('method', method);
    }
  }
}
