import { Controller } from "@hotwired/stimulus";

/**
 * Manages showing and hiding tab panels based on tab control clicks and ARIA attributes.
 */
export default class extends Controller {
    initialize() {
        this.tabContainer = this.element; // a parent element that contains all tab controls and panels.
        this.tabControls = this.tabContainer.querySelectorAll('[role="tab"]');
        this.tabPanels = this.tabContainer.querySelectorAll('[role="tabpanel"]');

        this.tabControls.forEach((tabControl) => {
            tabControl.addEventListener("click", this.toggleTabPanels.bind(this));
            tabControl.addEventListener("keydown", this.toggleTabPanels.bind(this));
        });

        // determine which tab control is selected, defaulting to the first one if none are selected.
        let selectedTab = this.tabControls[0];
        this.tabControls.forEach((tabControl) => {
            if (tabControl.getAttribute("aria-selected") === "true") {
                selectedTab = tabControl;
            }
        });

        this.setSelectedTab(selectedTab);
    }

    setSelectedTab(tabControl) {
        // hide all tab panels
        this.tabPanels.forEach((panel) => {
            panel.classList.add("!hidden");
        });

        // show the tab panel that the clicked tab control controls
        const tabPanel = this.tabContainer.querySelector(`#${tabControl.getAttribute("aria-controls")}`);
        tabPanel.classList.remove("!hidden");

        // set all tab controls as unselected
        this.tabControls.forEach((control) => {
            control.setAttribute("aria-selected", false);
        });

        // set the clicked tab control as selected
        tabControl.setAttribute("aria-selected", true);
    }

    toggleTabPanels(event) {
        // listen for key and click events
        if (event.type === "keydown" && event.key !== "Enter" && event.key !== " ") {
            return true; // allow event bubbling.
        }

        let tabControl = event.currentTarget;
        this.setSelectedTab(tabControl);

        return true;
    }
}
