import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static values = {
    lines: Number,
    readMore: Boolean,
    readMoreStyle: { type: String, default: '' }
  }

  connect() {
    this.descriptionTarget = this.element;
    const descriptionTextBox = this.descriptionTarget;
    this.originalText = descriptionTextBox.innerText;

    this.truncateDescription();

    this.originalOnResize = this.onResize;
    this.onResize = function(...args) {
      setTimeout(() => this.originalOnResize(...args), 100);
    }.bind(this);

    window.addEventListener("resize", this.onResize);
  }

  truncateDescription = () => {
    const descriptionTextBox = this.descriptionTarget;
    const lineHeight = parseFloat(getComputedStyle(descriptionTextBox).lineHeight);
    const numLines = this.linesValue;
    const maxHeight = (lineHeight * numLines) + 4; // add a few pixels to give a little wiggle room on line height.

    if (descriptionTextBox.scrollHeight > maxHeight) {
      const ellipsis = '...';
      const readMoreStyle = this.readMoreStyleValue ? ` style="color: ${this.readMoreStyleValue};"` : '';
      const readMoreToggle = ` <a href="#" class="read-more"${readMoreStyle} data-action="truncate-text#expand">Read more</a>`;
      let truncated_content = descriptionTextBox.textContent

      while (descriptionTextBox.scrollHeight > maxHeight) {
        truncated_content = truncated_content.slice(0, -1);
        descriptionTextBox.innerHTML = truncated_content + ellipsis;

        if (this.readMoreValue) {
          descriptionTextBox.innerHTML += readMoreToggle;
        }
      }
    }
  }

  onResize = () => {
    this.descriptionTarget.textContent = this.originalText
    this.truncateDescription()
  }

  expand(event) {
    event.preventDefault();
    const descriptionTextBox = this.descriptionTarget;
    descriptionTextBox.innerHTML = this.originalText;
  }
}
