import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['lightbox', 'video']

  connect() {
    window.document.onkeydown = (e) => {
      if (e.key === 'Escape') {
        this.close();
      }
    };
  }

  open() {
    window.scrollTo(0, 0)
    this.lightboxTarget.style.display = 'flex'
  }

  close() {
    this.lightboxTarget.style.display = 'none'
    if (this.hasVideoTarget) {
      this.videoTarget.pause()
    }
  }
}
